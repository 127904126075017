import React from 'react';
import { useSelector } from 'react-redux';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { getContent } from '../../reducers/infowindow';

import list from '../../config/amenities';

const Description = ({ isInfoCenter }) => {
  if (!isInfoCenter) return null;

  return (
    <>
      <p>
        Hours of Operation: 8:00 a.m. - 5:00 p.m. CT seven days a week - 8:00 a.m. - 6:00 p.m. Memorial Day weekend through Labor Day
        Closed only on Easter Sunday, Thanksgiving Day, the day before Christmas, Christmas Day, and New Year&apos;s Day
      </p>
      <p>Travelers may also call <a href="tel:+18004529292">1-800-452-9292</a> from 8 a.m. to 6 p.m. daily for travel information, professional trip planning assistance and road condition information. Automated road conditions 24 hours a day
      </p>
    </>
  );
};

const Amenities = ({ isInfoCenter }) => {
  const data = useSelector(getContent);
  let header = data.name;
  const link = `https://www.txdot.gov/discover/rest-areas-travel-information-centers/${data.type === 'rest-area' ? 'safety-rest-area-list' : 'travel-information-centers'}/${data.title}.html`;
  if (!isInfoCenter) {
    header = `Safety Rest Area at ${header}`;
  }
  return (
    <div>
      <h3>{header}</h3>
      <ImageList cols={7}>
        {
          data
            .amenities.filter((amenity) => list[amenity])
            .map((amenity) => <ImageListItem key={amenity}><img width="25" height="25" src={list[amenity]} alt={amenity} /></ImageListItem>)
        }
      </ImageList>
      <hr />
      <Description isInfoCenter={isInfoCenter} />
      <a href={link} target="_blank" rel="noopener noreferrer">See More Details Here <NorthEastIcon /></a>
    </div>
  );
};

export default Amenities;
