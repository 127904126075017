import React from 'react';

const style = {
  backgroundColor: 'rgb(247, 93, 59)',
  textAlign: 'center',
  fontSize: '20px',
  color: 'white',
  height: '40px',
  padding: '5px',
  fontWeight: 'bold',
  verticalAlign: 'middle',
};

const ContraflowBar = () => (
  <div style={style}>
    Contraflow Planning Mode
  </div>
);

export default ContraflowBar;
