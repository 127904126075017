import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getMapDiv, getPlacesAutocompleteOptions, getPlacesAutocompleteQuery, setDestinationDetails, setOriginDetails, setPlacesAutocompleteQuery } from '../reducers/map';
import { placesAutocompleteRequest, placesDetailsRequest } from '../thunks/places';

const renderInput = (params) => <TextField {...params} label="Search..." fullWidth />;

const renderOption = (props, option) => {
  // the matches array represents the indices of the query string that match the option text
  const matches = option.structured_formatting.main_text_matched_substrings || [];
  // used for highlighting the search query in the autocomplete options
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...props} style={{ zIndex: 5000 }} data-is-autocomplete-result>
      <Grid container alignItems="center" data-is-autocomplete-result>
        <Grid item sx={{ display: 'flex', width: 44 }} data-is-autocomplete-result>
          <LocationOnIcon sx={{ color: 'text.secondary' }} />
        </Grid>
        <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }} data-is-autocomplete-result>
          {parts.map((part, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              component="span"
              sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
              data-is-autocomplete-result
            >
              {part.text}
            </Box>
          ))}

          <Typography variant="body2" color="text.secondary" data-is-autocomplete-result>
            {option.structured_formatting.secondary_text}
          </Typography>

        </Grid>
      </Grid>
    </li>
  );
};

export const PlacesAutocomplete = ({ locationType = 'origin', isDirections }) => {
  const dispatch = useDispatch();

  const query = useSelector(getPlacesAutocompleteQuery(locationType));
  const autocompleteOptions = useSelector(getPlacesAutocompleteOptions(locationType));
  const mapDiv = useSelector(getMapDiv);

  const getAutocompleteResults = debounce((query) => dispatch(placesAutocompleteRequest({ locationType, query })), 400);

  const handleInputChange = (e, text) => {
    if (query !== text) {
      dispatch(setPlacesAutocompleteQuery({ locationType, query: text }));
      getAutocompleteResults(text);
    }
  };

  const handleChange = (event, selectedOption) => {
    if (!selectedOption) return;
    // if using the sidebar search, do a places details request and open infowindow
    if (!isDirections) return dispatch(placesDetailsRequest(selectedOption));

    // if doing a direction request, just set the origin or destination object and label
    const { place_id: placeId, description } = selectedOption;
    if (locationType === 'origin') return dispatch(setOriginDetails({ placeId, label: description }));
    dispatch(setDestinationDetails({ placeId, label: description }));
  };

  // abort rendering until Google Maps has loaded
  if (!mapDiv) return null;

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      options={autocompleteOptions}
      autoComplete
      includeInputInList
      filterSelectedOptions
      inputValue={query}
      noOptionsText="No locations"
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      sx={{ zIndex: 20 }}
      freeSolo
    />
  );
};
