import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, ListItemIcon, ListItem, ListItemText, ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HelpIcon from '@mui/icons-material/Help';
import UpdateIcon from '@mui/icons-material/Update';
import CallIcon from '@mui/icons-material/Call';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { setClosed, locateUser, openFAQ } from '../../reducers/info';
import { getDate } from '../../reducers/conditions';
import { formatDate } from '../../utils/formatDate';
import BootstrapDialogTitle from '../utility/BootstrapDialogTitle';
import { getMap } from '../../utils/map-google/mapUtils';
import config from '../../config';

const { txStateBounds } = config;

const ShowDate = () => {
  const ms = useSelector(getDate);
  return (
    <Typography variant="body2">
      Data last updated on {formatDate(ms, true)}.
    </Typography>
  );
};

const FAQ = (props) => (
  <ListItem disablePadding>
    <ListItemButton onClick={props.handleOpenFAQ} disableGutters>
      <ListItemIcon>
        <HelpIcon />
      </ListItemIcon>
      <ListItemText
        primary={(
          <Typography variant="body2" sx={{ textDecoration: 'underline', color: 'blue' }}>
            Frequently Asked Questions
          </Typography>
        )}
      />
    </ListItemButton>
  </ListItem>
);

const ContactUs = () => (
  <ListItem disablePadding>
    <ListItemIcon>
      <CallIcon />
    </ListItemIcon>
    <ListItemText
      primary={(
        <Typography variant="body2">
          Contact us at <a href="tel:+1-800-452-9292">+1-800-452-9292</a>
        </Typography>
      )}
    />
  </ListItem>
);

const LastUpdate = () => (
  <ListItem disablePadding>
    <ListItemIcon>
      <UpdateIcon />
    </ListItemIcon>
    <ListItemText primary={
      <ShowDate />
    }
    />
  </ListItem>
);

export default function IntroModal() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setClosed());
  const handleOpenFAQ = () => dispatch(openFAQ('FAQ'));
  const handleZoomToStateExtent = () => {
    const map = getMap();
    map.fitBounds(txStateBounds);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Welcome to Contraflow
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Welcome to TxDOT&apos;s DriveTexas™ Emergency Planning website, which provides evacuation route information for the State of Texas. This site is for planning purposes only and does not display live information. In the event that Contraflow or Evaculanes are activated, they will be displayed as current conditions with available access points on www.DriveTexas.org. Please do not use this site while operating a motor vehicle. Be Safe. Drive Smart. Thank you.
        </Typography>
        <Divider />
        <List dense>
          <FAQ handleOpenFAQ={handleOpenFAQ} />
          <LastUpdate />
          <ContactUs />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => dispatch(locateUser())}>
          My Location
        </Button>
        <Button variant="contained" onClick={handleZoomToStateExtent}>
          Default Location
        </Button>
      </DialogActions>
    </div>
  );
}
