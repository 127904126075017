import React from 'react';
import { useSelector } from 'react-redux';

import { getFloodContent } from '../../reducers/infowindow';

const Show = ({ show, children }) => {
  if (show) return <>{children}</>;
  return null;
};

const DataSource = ({ dpa }) => {
  switch (dpa) {
    case 'HOU': return <>Data Source: <a href="https://www.harriscountyfws.org" target="_blank" rel="noreferrer">Harris County Flood Control District</a></>;
    case 'BMT': return <>Data Source: <a href="https://dd6.onerain.com/map/" target="_blank" rel="noreferrer">Jefferson County Drainage District 6</a></>;
    default: return null;
  }
};

const FloodInfo = () => {
  const content = useSelector(getFloodContent);
  return (
    <>
      <h2>Roadway Flooding Danger</h2>
      <h4>High risk of roadway flooding in this area.</h4>
      <h4>Avoid unnecessary travel.</h4>
      <Show show={content && content.SensorUrl}>
        <a href={content.SensorUrl} target="_blank" rel="noreferrer">More Information</a>
      </Show><br />
      <Show show={content && content.RegionCode}>
        <DataSource dpa={content.RegionCode} />
      </Show>
      <p>Data available in select coastal areas only</p>
    </>
  );
};

export default FloodInfo;
