import { useSelector, useDispatch } from 'react-redux';

import { getFloodingLikely, getFloodingLikelyAvailable } from '../../../reducers/conditions';
import SidebarToggle from '../SidebarToggle';

import floodLayers from '../../../config/flooding';

const FloodingLikelyToggle = () => {
  const dispatch = useDispatch();
  const floodToggled = useSelector(getFloodingLikely);
  const floodAvailable = useSelector(getFloodingLikelyAvailable);

  if (!floodAvailable) return null;

  return (
    <>
      {floodLayers.map(({ id, name, imgSrc, Icon, iconColor, help, handleToggle }) => (
        <SidebarToggle
          key={id}
          name={name}
          checked={floodToggled}
          Icon={Icon}
          imgSrc={imgSrc}
          iconColor={iconColor}
          tooltip={help}
          onChange={() => dispatch(handleToggle(!floodToggled))}
        />
      ))}
    </>
  );
};

export default FloodingLikelyToggle;
