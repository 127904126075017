import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useEffect, useRef } from 'react';
import SidebarHeader from './SidebarHeader';

import Contraflow from './Contraflow';
import MoreResources from './MoreResources';
import ExternalLinks from './ExternalLinks';
import ConditionsSection from './conditions/ConditionsSection';
import { getPrintMode } from '../../reducers/print';
import { useAppbarHeight } from '../../hooks/useAppBarHeight';
import { getIsMobile, getSidebarOpen, setSidebarOpen } from '../../reducers/app';
import { PlacesAutocomplete } from '../PlacesAutocomplete';
import { getContraflowStatus, getRoute } from '../../reducers/contraflow';
import ContraflowTest from './ContraflowTest';
import { createShieldLayer, removeShieldLayer } from '../../utils/shields';

const createStyles = ({ printMode, appBarHeight, contraflowBarHeight, isMobile }) => {
  const desktopTop = contraflowBarHeight ? '7rem' : '4.7rem';
  const mobileTop = contraflowBarHeight ? '6.5rem' : '4rem';

  return ({
    root: {
      width: printMode ? 200 : 275,
      zIndex: 2,
      position: 'absolute',
      right: '0.5rem',
      top: desktopTop,
      maxHeight: printMode ? 'none' : `calc(100svh - ${appBarHeight + contraflowBarHeight}px - 40px)`,
      overflowY: 'auto',
    },
    rootMobile: {
      width: 230,
      zIndex: 2,
      position: 'absolute',
      right: '1rem',
      top: mobileTop,
      overflow: 'auto',
      maxHeight: printMode ? 'none' : `calc(100svh - ${appBarHeight + contraflowBarHeight}px - 40px)`,
    },
    icon: {
      position: 'absolute',
      right: '1rem',
      top: isMobile ? mobileTop : desktopTop,
    },
    hideMenuWrapper: {
      display: 'flex',
      justifyContent: 'right',
    },
    hideMenu: {
      justifyContent: 'right',
      borderRadius: '5px 5px 0 0',
    },
  }
  );
};

const Sidebar = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(getIsMobile);
  const sidebarOpen = useSelector(getSidebarOpen);
  const sidebarRef = useRef(null);
  const isContraflowOpen = useSelector(getContraflowStatus);
  const route = useSelector(getRoute);

  useEffect(() => {
    // set the default sidebar state based on the screen size
    if (isMobile) dispatch(setSidebarOpen(false));
    else dispatch(setSidebarOpen(true));
  }, [isMobile]);

  // when user clicks outside the sidebar when in mobile we should hide the sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isAutocompleteResult = event.target.getAttribute('data-is-autocomplete-result');
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !isAutocompleteResult) {
        dispatch(setSidebarOpen(false));
      }
    };
    if (isMobile) {
      window.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('click', handleClickOutside);
    }
    return () => {
      if (isMobile) {
        window.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('click', handleClickOutside);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    const showSheilds = !route && isContraflowOpen;
    if (showSheilds) {
      createShieldLayer();
    } else {
      removeShieldLayer();
    }
    return () => {
      removeShieldLayer();
    };
  }, [route, isContraflowOpen]);

  const handleToggle = () => dispatch(setSidebarOpen(!sidebarOpen));

  const printMode = useSelector(getPrintMode);
  const appBarHeight = useAppbarHeight();
  const contraflowBarHeight = isContraflowOpen ? 40 : 0;
  const styles = createStyles({ printMode, appBarHeight, contraflowBarHeight, isMobile });
  return (
    <div ref={sidebarRef}>
      <Button
        startIcon={<KeyboardBackspaceIcon />}
        variant="contained"
        size="small"
        aria-label="Open Menu"
        onClick={handleToggle}
        sx={styles.icon}
      >
        Show Menu
      </Button>
      {sidebarOpen && (
        <Paper sx={isMobile ? styles.rootMobile : styles.root} id="right-sidebar">
          {!printMode && (
            <div style={styles.hideMenuWrapper}>
              <Button
                variant="text"
                fullWidth
                endIcon={<ArrowRightAltIcon />}
                aria-label="Close Menu"
                onClick={handleToggle}
                sx={styles.hideMenu}
              >
                Hide Menu
              </Button>
            </div>
          )}
          <SidebarHeader />
          {!printMode && <PlacesAutocomplete />}
          { isContraflowOpen && <ContraflowTest />}
          { !isContraflowOpen && <Contraflow /> }
          { !isContraflowOpen && <ConditionsSection />}
          <MoreResources />
          {!printMode && <ExternalLinks />}

        </Paper>
      )}
    </div>

  );
};
export default Sidebar;
