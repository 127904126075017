import { getMap } from './map-google/mapUtils';
import { routes } from '../config/contraflow';

let _shields;
let onMap = false;
const makeSheilds = () => {
  const { google } = globalThis;
  if (_shields) {
    return _shields;
  }
  console.log('google', google);
  _shields = routes.map(({ shield, centroid }) => {
    const position = centroid;
    const image = document.createElement('img');
    image.src = shield;
    image.style.marginBottom = '-24px';
    return new google.maps.marker.AdvancedMarkerElement({
      content: image,
      position,
    });
  });
  return _shields;
};
export const createShieldLayer = () => {
  if (onMap) {
    return;
  }
  const shields = makeSheilds();
  console.log('shields', shields);
  const map = getMap();
  shields.forEach((marker) => marker.setMap(map));
  onMap = true;
};

export const removeShieldLayer = () => {
  if (!onMap) {
    return;
  }
  const shields = makeSheilds();
  shields.forEach((marker) => marker.setMap(null));
  onMap = false;
};
