import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';

import { toggleConditionsMenu, toggleFuture, toggleCondition, showCurrent, showFuture, toggleFloodingLikely } from '../reducers/conditions';
import { toggleTraffic, toggleRestAreas, toggleInfoCenters, toggleTrafficCameras, toggleLinksMenu, toggleResourceMenu } from '../reducers/resources';
import { setLatLng, setZoom } from '../reducers/map';

const defaultResources = {
  traffic: false,
  restAreas: false,
  infoCenters: false,
  cameras: false,
  open: false,
  linksOpen: false,
  print: false,
};

const makeQuery = ({ conditions: { current, future, showFuture, floodingLikely }, resources, map: { latLng: { lat, lng }, zoom }, print: { print } }) => {
  const obj = {};
  if (showFuture) {
    obj.v = 'f';
  }

  if (Object.values(current).some((item) => !item)) {
    const keys = Object.keys(current);
    keys.sort();
    let out = '';
    for (const key of keys) {
      if (!current[key]) {
        out += key;
      }
    }
    obj.ch = out;
  }

  if (Object.values(future).some((item) => !item)) {
    const keys = Object.keys(future);
    keys.sort();
    let out = '';
    for (const key of keys) {
      if (!future[key]) {
        out += key;
      }
    }
    obj.fh = out;
  }

  {
    const keys = Object.keys(resources);
    keys.sort();
    let out = '';
    for (const key of keys) {
      if (resources[key] !== defaultResources[key]) {
        out += key.slice(0, 1);
      }
    }
    if (out.length) {
      obj.r = out;
    }
  }

  if (lat || lng) {
    const z = zoom || 8;
    const precision = Math.max(0, Math.ceil(Math.log(z) / Math.LN2));
    obj.ll = [lng.toFixed(precision), lat.toFixed(precision)];
  }

  if (zoom) {
    obj.z = zoom;
  }

  if (print) {
    obj.print = true;
  }

  if (!floodingLikely) obj.fl = 'false';

  const keys = Object.keys(obj);
  if (!keys.length) {
    return '';
  }
  keys.sort();
  let out = '';
  for (const key of keys) {
    if (out.length) {
      out += '&';
    } else {
      out += '?';
    }
    out += key;
    out += '=';
    out += obj[key];
  }
  return out;
};

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isAnyOf(
    toggleFuture,
    toggleConditionsMenu,
    toggleCondition,
    showCurrent,
    showFuture,
    toggleTraffic,
    toggleRestAreas,
    toggleInfoCenters,
    toggleTrafficCameras,
    toggleLinksMenu,
    toggleResourceMenu,
    setLatLng,
    setZoom,
    toggleFloodingLikely,
  ),
  effect(action, api) {
    const currentState = api.getState();
    const newUrl = makeQuery(currentState);
    const oldUrl = window.location.search;
    if (newUrl !== oldUrl) {
      const url = new window.URL(window.location.href);
      url.search = newUrl;
      history.replaceState({}, false, url.toString());
    }
  },
});

export default listenerMiddleware.middleware;
