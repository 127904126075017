import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { closeSnackbar, getSnackBarState } from '../reducers/snackbar';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const SnackbarMessage = () => {
  const { open, message, severity, autoHideDuration } = useSelector(getSnackBarState);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(closeSnackbar());
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity={severity || 'info'}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarMessage;
