import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import VideocamIcon from '@mui/icons-material/Videocam';
import restAreaIcon from '../assets/resource-panel/picnic.png';
import informationIcon from '../assets/resource-panel/information.png';
import { toggleTraffic, toggleRestAreas, toggleInfoCenters, toggleTrafficCameras } from '../reducers/resources';

export default [
  {
    id: 'trafic',
    name: 'Traffic',
    handleToggle: toggleTraffic,
    Icon: DirectionsCarIcon,
    help: 'Traffic overlay provided by Google',
    contra: false,
  },
  {
    id: 'restAreas',
    name: 'Rest Areas',
    handleToggle: toggleRestAreas,
    contraHelp: 'Existing Safety Rest Areas along evacuation routes may be activated as Comfort Stations with oxygen, drinking water and ice.',
    imgSrc: restAreaIcon,
    contra: true,
  },
  {
    id: 'infoCenters',
    name: 'Travel Information Centers',
    handleToggle: toggleInfoCenters,
    imgSrc: informationIcon,
    help: 'Free maps, travel literature, and trip planning assistance '
          + 'from professional travel counselors',
    contra: true,
  },
  {
    id: 'trafficCameras',
    name: 'Traffic Cameras',
    handleToggle: toggleTrafficCameras,
    Icon: VideocamIcon,
    help: 'Live camera images, incident data and travel times for selected locations',
    contra: false,
  },
];
