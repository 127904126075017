import config from '../../config';

const { google: { mapOptions } } = config;

export default ({ zoom = mapOptions.zoom, lat = mapOptions.center.lat, lng = mapOptions.center.lng, satellite, print }) => ({
  mapId: process.env.REACT_APP_MAP_ID, // sets vector map styling, can be updated in GCP console GMP section
  center: { lat: Number(lat), lng: Number(lng) },
  zoom: Number(zoom),
  disableDefaultUI: true,
  mapTypeId: satellite ? window.google.maps.MapTypeId.SATELLITE : window.google.maps.MapTypeId.ROADMAP,
  streetViewControl: false,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.LEFT_TOP,
  },
  mapTypeControl: !print,
  mapTypeControlOptions: {
    position: window.google.maps.ControlPosition.TOP_LEFT,
    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
  },
  rotateControl: !print,
  rotateControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  zoomControl: !print,
  gestureHandling: 'greedy',
  scaleControl: true,
  minZoom: 5,
  maxZoom: 15,
  clickableIcons: false,
});
