import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIsMobile, setSidebarOpen } from '../reducers/app';
import { setSearchContent } from '../reducers/infowindow';
import { setDirectionsMode, setOriginDetails, setPlacesAutocompleteOptions } from '../reducers/map';
import { getAutocompleteResults, getPlacesDetails } from '../utils/map-google/places-autocomplete';

export const placesAutocompleteRequest = createAsyncThunk('map/placesAutocompleteRequest', async (payload, thunkAPI) => {
  const { locationType = 'origin', query = '' } = payload;
  if (!query) return thunkAPI.dispatch(setPlacesAutocompleteOptions({ locationType, options: [] }));
  const options = await getAutocompleteResults(query);
  thunkAPI.dispatch(setPlacesAutocompleteOptions({ locationType, options }));
});

export const placesDetailsRequest = createAsyncThunk('map/placesDetailsRequest', async (payload, thunkAPI) => {
  const isMobile = getIsMobile(thunkAPI.getState());
  thunkAPI.dispatch(setDirectionsMode(false));
  const { place_id: placeId, description } = payload;
  const { geometry } = await getPlacesDetails(placeId);
  if (isMobile) thunkAPI.dispatch(setSidebarOpen(false));
  thunkAPI.dispatch(setOriginDetails({ placeId, label: description }));
  thunkAPI.dispatch(setSearchContent({ label: description, geometry }));
});
