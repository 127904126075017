import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Tooltip from './Tooltip';

import { getPrintMode } from '../../reducers/print';

const createStyles = ({ iconColor, printMode }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '12px',
  },
  img: {
    width: '20px',
  },
  colorCircle: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    background: `${iconColor}B3`,
    border: `${iconColor} solid 2px`,
  },
  tooltipIcon: {
    width: '16px',
    opacity: '0.6',
  },
  title: {
    width: printMode ? 'auto' : 'min-content',
  },
});

const SidebarToggleLabel = ({ id, name, Icon, iconColor, imgSrc, tooltip }) => {
  const printMode = useSelector(getPrintMode);
  const styles = createStyles({ iconColor, printMode });
  return (
    <div style={styles.root} id={id}>
      {iconColor && <div style={styles.colorCircle} />}
      {Icon && <Icon />}
      {imgSrc && <img alt={name} src={imgSrc} style={styles.img} />}
      <Typography variant="body2" sx={styles.title}>{name}</Typography>
      { !printMode && tooltip && (
        <Tooltip
          text={tooltip}
        />

      )}
    </div>
  );
};

export default SidebarToggleLabel;
