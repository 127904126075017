export const styles = [{
  range: [0, 20],
  rules: [{
    style: {
      fillColor: '125-29-137-188',
      borderWidth: '5',
      borderColor: '180-29-137-188',
      borderClipping: 'Inside',
    } }],
}];
