import store from '../../store';
import restAreaIcon from '../../assets/resource-panel/picnic.png';
import informationIcon from '../../assets/resource-panel/information.png';
import cameraIcon from '../../assets/resource-panel/video.png';
import restAreaData from '../../data/safety_rest_areas.json';
import trafficCamerasData from '../../data/cameras.json';
import MarkerGroup from './marker-group';
import { setRestAreaContent, setInfoCenterContent } from '../../reducers/infowindow';

function getMarkers(data) {
  const result = data.map((obj) => {
    const latlng = new window.google.maps.LatLng(obj.geometry.lat, obj.geometry.lng);
    const marker = new window.google.maps.Marker({
      position: latlng,
      title: obj.name,
      rawData: obj,
    });

    return marker;
  });

  return result;
}

const createResourceLayers = () => {
  const { Point } = window.google.maps;
  const { Size } = window.google.maps;
  const layers = {};
  const restAreas = getMarkers(restAreaData);
  const informationCenters = getMarkers(restAreaData.filter(({ type }) => type === 'vc'));
  const trafficCameras = getMarkers(trafficCamerasData);

  layers['rest-areas'] = new MarkerGroup(restAreas, {
    url: restAreaIcon,
    size: new Size(20, 16),
    origin: new Point(0, 0),
    anchor: new Point(10, 16),
    scaledSize: new Size(20, 16),
  }, {
    click(marker, data) {
      store.dispatch(setRestAreaContent(marker.getPosition(), data));
    },
  });

  layers['information-centers'] = new MarkerGroup(informationCenters, {
    url: informationIcon,
    size: new Size(18, 18),
    origin: new Point(0, 0),
    anchor: new Point(9, 18),
    scaledSize: new Size(18, 18),
  }, {
    click(marker, data) {
      store.dispatch(setInfoCenterContent(marker.getPosition(), data));
    },
  });

  layers['traffic-cameras'] = new MarkerGroup(trafficCameras, {
    url: cameraIcon,
    size: new Size(17, 12),
    origin: new Point(0, 0),
    anchor: new Point(8, 12),
    scaledSize: new Size(17, 12),
  }, {
    click(marker, data) {
      window.open(data.url);
    },
  });
  return layers;
};

export default createResourceLayers;
