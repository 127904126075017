import conditions from './conditions';

export default {
  sidebarWidth: 400,
  drawerHeight: '50vh',
  basemaps: {
    carto_grey: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
    usgs_topo: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}',
  },
  google: {
    mapOptions: {
      center: { lat: 32.34, lng: -99.5 },
      zoom: 7,
    },
  },
  txStateBounds: { south: 25.85922, west: -106.61132, north: 36.54494, east: -93.47167 },
};

export { conditions };
