import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';

const BootstrapDialogTitle = ({
  children,
  onClose,
  ...other
}) => (
  <DialogTitle
    sx={{
      m: 0,
      p: 2,
    }}
    variant="h5"
    component="h1"
    {...other}
  >
    {children}
    {
      onClose
        ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )
        : null
    }
  </DialogTitle>
);

export default BootstrapDialogTitle;
