import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

import { getDirections, getPlacesAutocompleteQuery, getOriginLabel } from '../../../reducers/map';

export const GoogleMapsDirectionsButton = () => {
  const originLabel = useSelector(getOriginLabel);
  const destinationLabel = useSelector(getPlacesAutocompleteQuery('destination'));
  const directions = useSelector(getDirections);
  if (!directions) return null;
  return (
    <Button
      variant="outlined"
      size="small"
      component="a"
      rel="noopener"
      href={`https://www.google.com/maps/dir/?api=1&origin=${originLabel}&destination=${destinationLabel}`}
      target="_blank"
    >
      Google Maps
    </Button>
  );
};
