const directionalIcon = 'https://storage.googleapis.com/drivetexas/symbology/directional.png';

export const styles = [
  {
    range: [0, 9],
    rules: [
      {
        style: {
          angleOffset: '0',
          antiAliasing: 'true',
          blending: 'true',
          drawLine: 'true',
          endCap: 'Round',
          fillColor: '255-62-67-115',
          iconSpacing: 40,
          lineOffset: '0',
          startCap: 'Round',
          url: directionalIcon,
          width: '10',
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'Y',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-255-16-107',
          lineOffset: '5',
          startCap: 'Round',
          width: '4',
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'X',
            },
          ],
        ],
      },
    ],
  },
  {
    range: [10, 22],
    rules: [
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-198-0-5',
          startCap: 'Round',
          width: 7,
          borders: [
            {
              fillColor: '255-142-4-4',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'A',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-0-66-248',
          startCap: 'Round',
          width: 7,
          borders: [
            {
              fillColor: '255-0-48-181',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'Z',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-157-0-202',
          startCap: 'Round',
          width: 7,
          borders: [
            {
              fillColor: '255-97-0-127',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'D',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-252-212-0',
          startCap: 'Round',
          width: '7',
          borders: [
            {
              fillColor: '255-214-178-2',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'O',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-217-217-217',
          startCap: 'Round',
          width: '7',
          borders: [
            {
              fillColor: '255-127-127-127',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'I',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-0-172-243',
          startCap: 'Round',
          width: '7',
          borders: [
            {
              fillColor: '255-0-136-192',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'F',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-239-92-26',
          startCap: 'Round',
          width: '7',
          borders: [
            {
              fillColor: '255-200-72-14',
              width: 9,
            },
          ],
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'C',
            },
          ],
        ],
      },
      {
        style: {
          angleOffset: '0',
          antiAliasing: 'true',
          blending: 'true',
          drawLine: 'true',
          endCap: 'Round',
          fillColor: '255-62-67-115',
          iconSpacing: 40,
          lineOffset: '0',
          startCap: 'Round',
          url: directionalIcon,
          width: '10',
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'Y',
            },
          ],
        ],
      },
      {
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-255-16-107',
          lineOffset: '5',
          startCap: 'Round',
          width: '4',
        },
        where: [
          [
            {
              col: 'CNSTRNTTYPECD',
              test: 'Equal',
              value: 'X',
            },
          ],
        ],
      },
    ],
  },
];
