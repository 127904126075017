import React, { Fragment } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getMLContent, getCurrentPage, getTotalPages, setCurrentPage } from '../../reducers/infowindow';
import { conditions } from '../../config';
import { formatDate } from '../../utils/formatDate';
import { getIsFuture } from '../../reducers/conditions';

const directions = {
  N: 'Northbound',
  S: 'Southbound',
  E: 'Eastbound',
  W: 'Westbound',
  NS: 'North and Southbound',
  EW: 'East and Westbound',
};

const style = {
  title: {
    margin: '10px 0',
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label_success: { backgroundColor: '#5cb85c',
    fontSize: '12px',
    color: 'white',
    padding: '0.2rem',
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center' },
  label_default: { backgroundColor: '#999',
    fontSize: '12px',
    color: 'white',
    padding: '0.2rem',
    borderRadius: '5px',
    fontWeight: 'bold',
    textAlign: 'center' },
  divider: {
    margin: '0.5rem',
  },
  buttonDiv: {
    textAlign: 'center',
  },
};

const getConditionTitle = (type) => {
  const cond = conditions.find(({ key }) => key === type);
  if (!cond) return 'Unknown';
  return cond.name;
};

const Show = ({ show, children }) => (show ? <>{children}</> : null);

const formatHighway = (val) => val && `${val.substr(0, 2)} ${val.substr(2, 7).replace(/^[0]+/g, '')}`;

const direction = (key) => directions[key] || '';

const MaplargeItem = () => {
  const content = useSelector(getMLContent);
  const currentPage = useSelector(getCurrentPage);
  const isFuture = useSelector(getIsFuture);
  if (!content.length) return null;
  const condition = content[currentPage];

  return (
    <div className="meta">
      <h4 style={style.title}>
        {getConditionTitle(condition.CNSTRNTTYPECD)} <Show show={condition.RDWAYNM}>on {condition.RDWAYNM}</Show> <Show show={condition.RTENM}>({formatHighway(condition.RTENM)})</Show>
      </h4>

      <Grid container spacing={2}>
        <Show show={isFuture && condition.CONDSTARTTS}>
          <Grid item xs={6}>
            Expected to start on
            <div style={style.label_success} className="label label-success">
              {formatDate(condition.CONDSTARTTS, true)}
            </div>
          </Grid>
        </Show>

        <Show show={condition.CONDENDTS}>
          <Grid item xs={6}>
            Expected to end on
            <div style={style.label_success} className="label label-success">
              {formatDate(condition.CONDENDTS, true)}
            </div>
          </Grid>
        </Show>

        <Show show={condition.TRVLDRCTCD}>
          <Grid item xs={6}>
            Affected Direction
            <div style={style.label_default} className="label label-default">
              {direction(condition.TRVLDRCTCD)}
            </div>
          </Grid>
        </Show>
      </Grid>

      <Divider sx={style.divider} />
      <div>{condition.CONDDSCR.split('<br/>').map((item, i) => <Fragment key={i}><Show show={i}><br /></Show>{item}</Fragment>)}</div> {/* eslint-disable-line react/no-array-index-key */}
    </div>
  );
};

const MaplargeButtons = () => {
  const currentPage = useSelector(getCurrentPage);
  const totalPages = useSelector(getTotalPages);
  const dispatch = useDispatch();
  const onPrevClick = () => dispatch(setCurrentPage(currentPage - 1));
  const onNextClick = () => dispatch(setCurrentPage(currentPage + 1));
  return (
    <>
      <div style={style.buttonDiv}>
        <Button disabled={currentPage === 0} onClick={onPrevClick} startIcon={<ChevronLeftIcon />} />
        {currentPage + 1} of {totalPages}
        <Button disabled={currentPage + 1 === totalPages} onClick={onNextClick} startIcon={<ChevronRightIcon />} />
      </div>
    </>
  );
};

const MaplargeInfo = () => <><MaplargeItem /><MaplargeButtons /></>;

export default MaplargeInfo;
