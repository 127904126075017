import { createSlice } from '@reduxjs/toolkit';
import { getPopup } from '../utils/map-google/mapUtils';

const initialState = {
  type: '',
  floodContent: null,
  content: null,
  contraflowContent: null,
  accessContent: null,
  mlContent: null,
  currentPage: 0,
  totalPages: 0,
  latlng: null,
  open: false,
  tab: null,
  time: 0,
  showMarker: false,
  noZoom: false,
};
const infoReducer = createSlice({
  name: 'infowindow',
  initialState,
  reducers: {
    setMaplargeContent: {
      prepare(latlng, content) {
        return {
          payload: { latlng, content },
        };
      },
      reducer(state, { payload }) {
        if (Date.now() - state.time < 50) {
          return;
        }
        state.mlContent = payload.content;
        state.floodContent = null;
        state.content = null;
        state.tab = null;
        state.latlng = payload.latlng;
        state.open = true;
        state.type = 'maplarge';
        state.currentPage = 0;
        state.noZoom = false;
        state.totalPages = payload.content.length;
      },
    },
    setFloodContent: {
      prepare(latlng, content) {
        return {
          payload: { latlng, content },
        };
      },
      reducer(state, { payload }) {
        state.floodContent = payload.content;
        state.mlContent = null;
        state.tab = null;
        state.latlng = payload.latlng;
        state.open = true;
        state.type = 'flood';
        state.currentPage = 0;
        state.totalPages = 0;
        state.noZoom = false;
      },
    },
    setAccessContent: {
      prepare(latlng, content, time) {
        return {
          payload: { time, latlng, content },
        };
      },
      reducer(state, { payload: { time, latlng, content } }) {
        state.floodContent = null;
        state.content = null;
        state.accessContent = content;
        state.aTime = time;
        const diff = state.aTime - (state.cTime || 0);
        if (diff > 50) {
          state.contraflowContent = null;
        }
        state.mlContent = null;
        state.tab = null;
        state.latlng = latlng;
        state.open = true;
        state.type = 'contraflow';
        state.currentPage = 0;
        state.totalPages = 0;
        state.noZoom = false;
      },
    },
    setOpen(state, { payload: latlng }) {
      state.latlng = latlng;
      state.open = true;
      state.type = 'search';
      state.noZoom = true;
    },
    setContraflowContent: {

      prepare(latlng, content, time) {
        return {
          payload: { time, latlng, content },
        };
      },
      reducer(state, { payload: { time, latlng, content } }) {
        state.floodContent = null;
        state.content = null;
        state.contraflowContent = content;
        state.cTime = time;
        const diff = state.cTime - (state.aTime || 0);
        if (diff > 50) {
          state.accessContent = null;
        }
        state.mlContent = null;
        state.tab = null;
        state.latlng = latlng;
        state.open = true;
        state.type = 'contraflow';
        state.currentPage = 0;
        state.totalPages = 0;
        state.noZoom = false;
      },
    },
    setBothContent: {
      prepare(latlng, flood, maplarge, time) {
        return {
          payload: { latlng, content: { flood, maplarge, time } },
        };
      },
      reducer(state, { payload }) {
        state.time = payload.content.time;
        state.floodContent = payload.content.flood;
        state.mlContent = payload.content.maplarge;
        state.latlng = payload.latlng;
        state.tab = 'maplarge';
        state.open = true;
        state.type = 'both';
        state.currentPage = 0;
        state.totalPages = payload.content.maplarge.length;
        state.noZoom = false;
      },
    },
    setRestAreaContent: {
      prepare(latlng, content) {
        return {
          payload: { latlng, content },
        };
      },
      reducer(state, { payload }) {
        state.mlContent = null;
        state.floodContent = null;
        state.latlng = payload.latlng;
        state.open = true;
        state.type = 'restarea';
        state.content = payload.content;
        state.noZoom = false;
      },
    },
    setInfoCenterContent: {
      prepare(latlng, content) {
        return {
          payload: { latlng, content },
        };
      },
      reducer(state, { payload }) {
        state.mlContent = null;
        state.floodContent = null;
        state.latlng = payload.latlng;
        state.open = true;
        state.type = 'infocenter';
        state.content = payload.content;
        state.noZoom = false;
      },
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
      // force popup to detect and reposition map if new content overlaps with UI
      const popup = getPopup();
      popup.open();
      popup.draw();
    },
    setTab(state, { payload }) {
      state.tab = payload;
      // force popup to detect and reposition map if new content overlaps with UI
      const popup = getPopup();
      popup.open();
      popup.draw();
    },
    setClosed() {
      return initialState;
    },
    setInfoLoc(state, { payload }) {
      state.latlng = payload;
      state.showMarker = false;
      state.noZoom = false;
    },
    setSearchContent(state, { payload: { geometry } }) {
      state.open = true;
      state.type = 'search';
      state.latlng = geometry.location;
      state.showMarker = true;
      state.noZoom = false;
    },
  },
});

export default infoReducer.reducer;

export const getContent = (state) => state.infowindow.content;
export const getType = (state) => state.infowindow.type;
export const getCurrentPage = (state) => state.infowindow.currentPage;
export const getTotalPages = (state) => state.infowindow.totalPages;
export const getLatlng = (state) => state.infowindow.latlng;
export const getOpen = (state) => state.infowindow.open;
export const getFloodContent = (state) => state.infowindow.floodContent;
export const getMLContent = (state) => state.infowindow.mlContent;
export const getTab = (state) => state.infowindow.tab;
export const getAccessContent = (state) => state.infowindow.accessContent;
export const getContraflowContent = (state) => state.infowindow.contraflowContent;
export const getShowMarker = (state) => state.infowindow.showMarker;
export const getNoZoom = (state) => state.infowindow.noZoom;
export const { setMaplargeContent } = infoReducer.actions;
export const { setBothContent } = infoReducer.actions;
export const { setFloodContent } = infoReducer.actions;
export const { setCurrentPage } = infoReducer.actions;
export const { setTab } = infoReducer.actions;
export const { setClosed } = infoReducer.actions;

export const { setRestAreaContent } = infoReducer.actions;
export const { setInfoCenterContent } = infoReducer.actions;

export const { setSearchContent, setContraflowContent, setAccessContent, setInfoLoc, setOpen } = infoReducer.actions;
