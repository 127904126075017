import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { getTab, setTab } from '../../reducers/infowindow';
import FloodInfo from './FloodInfo';
import MaplargeInfo from './MaplargeInfo';

const BothInfo = () => {
  const tab = useSelector(getTab);
  const dispatch = useDispatch();
  return (
    <>
      <ButtonGroup aria-label="outlined button group">
        <Button sx={{ pointerEvents: tab !== 'maplarge' ? 'auto' : 'none' }} variant={tab === 'maplarge' ? 'contained' : 'outlined'} onClick={() => dispatch(setTab('maplarge'))}>Conditions</Button>
        <Button sx={{ pointerEvents: tab !== 'flood' ? 'auto' : 'none' }} variant={tab === 'flood' ? 'contained' : 'outlined'} onClick={() => dispatch(setTab('flood'))}>Flooding Likely</Button>
      </ButtonGroup>

      {tab === 'flood' ? <FloodInfo /> : tab === 'maplarge' ? <MaplargeInfo /> : null} {/* eslint-disable-line no-nested-ternary */}
    </>
  );
};
export default BothInfo;
