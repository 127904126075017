export const formatDate = (date, includeTime) => {
  if (!date) return '';

  const d = new Date(Number(date));
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  if (includeTime) {
    options = {
      ...options,
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
  }

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(d);

  return formattedDate;
};
