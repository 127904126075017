import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  severity: 'info',
  autoHideDuration: 5000,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, { payload }) => {
      state.open = true;
      state.message = payload.message;
      state.severity = payload.severity;
      state.autoHideDuration = payload.autoHideDuration !== null ? initialState.autoHideDuration : payload.autoHideDuration;
    },
    closeSnackbar: () => initialState,
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const getSnackBarState = (state) => state.snackbar;

export default snackbarSlice.reducer;
