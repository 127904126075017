import { configureStore } from '@reduxjs/toolkit';
import setUrl from './utils/set-url';

import app from './reducers/app';
import print from './reducers/print';
import conditions from './reducers/conditions';
import infowindow from './reducers/infowindow';
import resources from './reducers/resources';
import map from './reducers/map';
import info from './reducers/info';
import snackbar from './reducers/snackbar';
import contraflow from './reducers/contraflow';
// use Redux DevTools compose if in a development environment
const isDev = process.env.REACT_APP_ENV !== 'production';

const reducer = {
  app,
  print,
  conditions,
  map,
  infowindow,
  resources,
  info,
  snackbar,
  contraflow,
};

const store = configureStore({
  reducer,
  devTools: isDev,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['map/setMapDiv', 'conditions/setConditionLayer', 'infowindow/setMaplargeContent', 'infowindow/setRestAreaContent', 'infowindow/setInfoCenterContent', 'infowindow/setFloodContent', 'infowindow/setBothContent', 'infowindow/setContraflowContent', 'infowindow/setAccessContent', 'infowindow/setSearchContent', 'infowindow/setOpen'],
      ignoredPaths: ['map.mapDiv', 'conditions.layer.payload', 'infowindow.latlng', 'infowindow.copy.latlng', 'infowindow.content.latLng', 'map.directions.bounds'],
    },
  }).prepend(setUrl),
});

export default store;
