import accidentsImgSrc from '../assets/symbology/accident.png';
import closuresImgSrc from '../assets/symbology/closed.png';
import constructionImgSrc from '../assets/symbology/construction.png';
import damageImgSrc from '../assets/symbology/damage.png';
import floodImgSrc from '../assets/symbology/flooding.png';
import iceImgSrc from '../assets/symbology/icesnow.png';
import otherImgSrc from '../assets/symbology/other.png';
import enterImgSrc from '../assets/symbology/enter.png';
import exitImgSrc from '../assets/symbology/exit.png';

const conditions = [
  {
    id: 'accidents',
    key: 'A',
    name: 'Accidents',
    icon: 'images/symbology/accident.png',
    imgSrc: accidentsImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'closures',
    key: 'Z',
    name: 'Closures',
    icon: 'images/symbology/closed.png',
    imgSrc: closuresImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'construction',
    key: 'C',
    name: 'Construction',
    icon: 'images/symbology/construction.png',
    imgSrc: constructionImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'damage',
    key: 'D',
    name: 'Damage',
    icon: 'images/symbology/damage.png',
    imgSrc: damageImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'flood',
    key: 'F',
    name: 'Flood',
    icon: 'images/symbology/flooding.png',
    imgSrc: floodImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'ice',
    key: 'I',
    name: 'Ice/Snow',
    icon: 'images/symbology/icesnow.png',
    imgSrc: iceImgSrc,
    show: true,
    enabled: true,
  }, {
    id: 'other',
    key: 'O',
    name: 'Other',
    icon: 'images/symbology/other.png',
    imgSrc: otherImgSrc,
    show: true,
    enabled: true,
    help: 'Includes conditions not listed above, such as parades, '
          + 'special events, low visibility, etc',
  },
  {
    id: 'contraflow',
    key: 'Y',
    hide: true,
    enabled: true,
    disabledByUser: false,
    name: 'Contraflow',
    altIcon: 'images/symbology/contraflow.png',
    lineIcon: true,
    show: true,
    help: 'Reversal of travel direction on inbound lanes of a highway so that all lanes carry traffic away from the affected area.',
  }, {
    id: 'evaculane',
    key: 'X',
    name: 'Evaculane',
    altIcon: 'images/symbology/evaculane.png',
    help: 'Officially designated highway shoulder which can be opened to traffic as an additional travel lane.',
    lineIcon: true,
    hide: true,
    enabled: true,
    disabledByUser: false,
    show: true,
  },
  {
    id: 'accessEnt',
    key: 'N',
    name: 'Contraflow Entrance',
    altIcon: 'images/symbology/enter.png',
    imgSrc: enterImgSrc,
    lineIcon: true,
    hide: true,
    enabled: true,
    disabledByUser: false,
    show: true,
    help: 'Existing highway ramp converted to entrance ramp onto Contraflow lanes. Ramps on the normal travel lanes are not affected.',
  }, {
    id: 'accessExt',
    key: 'L',
    name: 'Contraflow Exit',
    altIcon: 'images/symbology/exit.png',
    imgSrc: exitImgSrc,
    lineIcon: true,
    hide: true,
    enabled: true,
    disabledByUser: false,
    show: true,
    help: 'Existing highway ramp converted to exit ramp off of Contraflow lanes. Ramps on the normal travel lanes are not affected.',
  },
];

export default conditions;

export const conditionsById = Object.fromEntries(conditions.map((c) => [c.id, c]));
