import { createTheme, alpha } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const appTheme = createTheme({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  main: {
    width: '100vw',
    display: 'flex',
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&:not(.Mui-checked).Mui-focusVisible': {
            backgroundColor: alpha(grey[800], 0.4),
          },
        },
      },
    },
  },
});
