import { useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getPrintMode } from '../../reducers/print';

const styles = {
  summary: {
    background: '#f5f5f5',
    boxShadow: '0px 1px 1px rgb(0 0 0 / 10%)',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    zIndex: 20,
  },
  details: {
    padding: 0,
  },
};

const SidebarSection = ({ text, open, onChange, children }) => {
  const printStatus = useSelector(getPrintMode);

  return (
    <Accordion disableGutters expanded={open} onChange={onChange}>
      <AccordionSummary
        sx={styles.summary}
        expandIcon={!printStatus && <ExpandMoreIcon />}
      // aria-controls="panel1bh-content"
      >
        <Typography variant="body2" component="h2">
          {text}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
export default SidebarSection;
