import Paper from '@mui/material/Paper';

const styles = {
  overlay: {
    position: 'absolute',
    top: '95px',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: '#ccc',
    opacity: 0.8,
    paddingTop: '18px',
    fontSize: '1.3em',
    color: '#333',
    zIndex: 2,
  },
};

const Disabled = ({ show }) => {
  if (show) {
    return <></>;
  }
  return (
    <Paper sx={styles.overlay}>
      Intentionally Disabled
    </Paper>
  );
};

export default Disabled;
