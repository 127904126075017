import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { setClosed } from '../../reducers/info';
import { setPrintTitle, getPrintTitle } from '../../reducers/print';
import BootstrapDialogTitle from '../utility/BootstrapDialogTitle';
import openPrintModal from '../../utils/openPrintModal';

const style = {
  button: { width: 'fit-content',
    textTransform: 'none',
    fontWeight: 'regular',
  },
  input: {
    width: 500,
  },
  label: {
    color: 'black',
    fontSize: '18px',
  },
};

export default function PrintModal() {
  const dispatch = useDispatch();
  const printTitle = useSelector(getPrintTitle);
  const handleClose = () => {
    dispatch(setPrintTitle(''));
    dispatch(setClosed());
  };
  const onChange = (e) => dispatch(setPrintTitle(e.target.value));
  return (
    <div>

      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h6">Print map</Typography>
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <Stack spacing={2} component="form">
          <InputLabel sx={style.label} htmlFor="maptitle">Title:</InputLabel>

          <FormControl variant="standard">

            <OutlinedInput
              id="maptitle"
              value={printTitle}
              placeholder="Optional"
              aria-describedby="Input map Title. It is optional"
              onChange={onChange}
              style={style.input}
            />
          </FormControl>

          <Button
            onClick={() => {
              openPrintModal(printTitle);
              handleClose();
            }}
            sx={style.button}
            variant="contained"
          >Create Printable Map
          </Button>
        </Stack>
      </DialogContent>

    </div>
  );
}
