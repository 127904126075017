import TileLayer from 'maplarge-google';

const fields = [
  'CNSTRNTTYPECD',
  'TRVLDRCTCD', 'CONDSTARTTS',
  'CONDENDTS', 'RTENM', 'ROWUPDTSTATFLAG',
  'RDWAYNM', 'CONDDSCR',
  'sort',
];
const noop = () => {};
const updateLayerQuery = (layer, keys) => {
  // if (!layer.getMap()) {
  //   layer.setMap(this.map);
  // }
  if (!keys || !keys.length) {
    return layer.updateQuery([{
      col: 'CNSTRNTTYPECD',
      test: 'ContainsNone',
      value: 'Q',
    }]);
  }
  layer.updateQuery([{
    col: 'CNSTRNTTYPECD',
    test: 'ContainsNone',
    value: `${keys.join(',')}`,
  }]);
};
const deactivateGroup = (group) => {
  if (!group) {
    return;
  }

  group.lines.setMap(null);
  group.points.setMap(null);
  group.active = false;
};
const nonDeactivated = new Set(['X', 'Y', 'N', 'L']);
class LayerManager {
  constructor(activeConditions, map, options, callback) {
    this.currentZoom = 0;
    this.toLineZoom = 5;
    this.lineEnabled = false;
    this.map = map;
    this.click = callback || noop;
    this.onRefreshed = options.onRefreshed || noop;
    this.maplarge = options.maplarge;
    this.layers = options.layers;
    this.activeConditions = activeConditions;
    this.groups = {};
    this.otherGroup = null;
    this.otherGroupConditions = null;
    this.active = true;
    Object.keys(this.layers).forEach((groupId) => {
      const group = this.layers[groupId];
      const { maplarge } = this;

      maplarge.click = this.onClick.bind(this);

      this.groups[groupId] = {
        id: groupId,
        active: false,
        lines: new TileLayer({ sort: 'sort.desc',
          name: 'condLine',
          type: 'line',
          zindex: 2,
          ...group.lines,
          ...maplarge,
          fields }),
        points: new TileLayer({ sort: 'sort.desc',
          name: 'condPoint',
          type: 'point',
          zindex: 3,
          ...group.points,
          ...maplarge,
          fields }),
      };
      this.groups[groupId].points.on('fullTable', () => {
        Promise.all([
          this.groups[groupId].points.getFields('CNSTRNTTYPECD'),
          this.groups[groupId].points.getFields('lastUpdated'),
        ]).then((resp) => {
          if (resp[1].length) {
            this.groups[groupId].points.emit('refreshed', {
              types: resp[0].map((item) => item.CNSTRNTTYPECD),
              date: resp[1][0].lastUpdated,
            });
          }
        }).catch((e) => this.groups[groupId].points.emit('error', e));
      });
      this.groups[groupId].lines.on('fullTable', () => {
        Promise.all([
          this.groups[groupId].lines.getFields('CNSTRNTTYPECD'),
          this.groups[groupId].lines.getFields('lastUpdated'),
        ]).then((resp) => {
          if (resp[1].length) {
            this.groups[groupId].lines.emit('refreshed', {
              types: resp[0].map((item) => item.CNSTRNTTYPECD),
              date: resp[1][0].lastUpdated,
            });
          }
        }).catch((e) => this.groups[groupId].lines.emit('error', e));
      });
      this.groups[groupId].points.on('refreshed', (data) => this.onRefreshed(groupId, data));
    });
  }

  switchTo(groupId) {
    const group = this.groups[groupId];

    deactivateGroup(this.lastActiveGroup);
    this.activateGroup(group);
    this.lastActiveGroup = group;
    this.recalculateConditions();
    // if (this.otherGroup === groupId) {
    //   this.otherGroup = null;
    //   for (const [condition, value] of Object.entries(this.otherGroupConditions)) {
    //     if (!value) {
    //       this.toggleLayer(condition, value);
    //     }
    //   }
    //   this.otherGroupConditions = null;
    // }
  }

  setOther(groupId, conditions) {
    this.otherGroup = groupId;
    this.otherGroupConditions = conditions;
  }

  toggleLayer(layerId, enabled) {
    const conditions = this.activeConditions[this.lastActiveGroup.id];
    const condition = conditions.find((item) => item.key === layerId);

    if (condition) {
      condition.enabled = enabled === undefined ? !condition.enabled : enabled;
    }

    this.recalculateConditions();
  }

  recalculateConditions() {
    const group = this.lastActiveGroup;

    if (!group) {
      return;
    }
    const conditions = this.activeConditions[group.id];

    const keys = conditions.filter((condition) => !condition.enabled || !condition.show)
      .map((condition) => condition.key);

    if (this.lineEnabled) {
      updateLayerQuery(group.lines, keys);
    } else {
      updateLayerQuery(group.lines, []);
    }

    updateLayerQuery(group.points, keys);
  }

  toJSON() { // eslint-disable-line
    return '<Ml-Layer-Manager>';
  }

  deactivateCurent() {
    this.active = false;
    deactivateGroup(this.lastActiveGroup);
  }

  reactivateCurrent() {
    this.active = true;
    this.activateGroup(this.lastActiveGroup);
  }

  activateGroup(group) {
    if (!group || group.disabled) {
      return;
    }

    if (this.lineEnabled) {
      group.lines.setMap(this.map);
    }

    group.points.setMap(this.map);
    group.active = true;
  }

  deactiveConditions() {
    const group = this.groups.current;
    if (group.disabled) {
      return;
    }
    for (const condition of this.activeConditions.current) {
      if (nonDeactivated.has(condition.key)) {
        continue;
      }
      condition.prev = condition.enabled;
      condition.enabled = false;
    }
    group.disabled = true;
    this.recalculateConditions();
  }

  reactiveConditions() {
    const group = this.groups.current;
    if (!group.disabled) {
      return;
    }
    for (const condition of this.activeConditions.current) {
      if (nonDeactivated.has(condition.key)) {
        continue;
      }
      condition.enabled = condition.prev;
      delete condition.prev;
    }
    group.disabled = false;
    this.recalculateConditions();
  }

  enableGroups(ids) {
    Object.keys(this.groups).forEach((id) => {
      if (ids.indexOf(id) !== -1) {
        const group = this.groups[id];
        group.disabled = false;

        if (group === this.lastActiveGroup) {
          this.activateGroup(group);
        }
      }
    });
  }

  disableGroups(ids) {
    Object.keys(this.groups).forEach((id) => {
      if (ids.indexOf(id) !== -1) {
        const group = this.groups[id];
        group.disabled = true;
        deactivateGroup(group);
      }
    });
  }

  set zoom(zoom) {
    if (zoom >= this.toLineZoom) {
      this.lineEnabled = true;
    } else {
      this.lineEnabled = false;
    }

    this.currentZoom = zoom;

    if (this.lastActiveGroup && this.active) {
      this.activateGroup(this.lastActiveGroup);
      this.recalculateConditions();
    }
  }

  get zoom() {
    return this.currentZoom;
  }

  manualClick(e) {
    const { points } = this.lastActiveGroup;
    const { lines } = this.lastActiveGroup;
    const lineResult = lines.utfGrid._objectForEvent(e);
    if (lineResult.data) {
      return lineResult.data;
    }
    const pointResult = points.utfGrid._objectForEvent(e);
    if (pointResult.data) {
      return pointResult.data;
    }
  }

  onClick(result, ret) {
    const { map } = this;
    const { points } = this.lastActiveGroup;
    const { lines } = this.lastActiveGroup;
    const { latLng } = result;
    const { data } = result;
    let prom;
    if (!data && !ret) {
      return this.click();
    }

    if (map.getZoom() >= 10) {
      prom = Promise.all([
        points.getInfo(latLng.lat(), latLng.lng(), map.getZoom()),
        lines.getInfo(latLng.lat(), latLng.lng(), map.getZoom()),
      ]).then((resp) => resp[0].concat(resp[1]));
    } else {
      prom = points.getInfo(latLng.lat(), latLng.lng(), map.getZoom()).then((points) => {
        if (!points.length) {
          return lines.getInfo(latLng.lat(), latLng.lng(), map.getZoom());
        }
        return points;
      });
    }
    const out = prom.then((resp) => {
      if (resp.length) {
        return resp;
      }
      return data;
    });

    if (ret) {
      return out;
    }
    out.then((resp) => {
      this.click(resp, latLng);
    }).catch((e) => {
      console.error('click err', e);
    });
  }
}

export default LayerManager;
