import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import txDotLogoSrc from '../assets/txdot-logo-white.svg';
import dtLogoSrc from '../assets/drive-texas.svg';
import { getIsMobile } from '../reducers/app';

const styles = {
  txDotLogo: {
    width: '200px',
  },
  dtLogoWrapper: {
    width: '100px',
    height: '45px',
    background: 'white',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dtLogoWrapperInner: {
    width: '70px',
    height: '40px',
  },
  dtLogo: {
    height: '40px',
    marginTop: '3px',
  },
  cover: {
    background: 'white',
    borderRadius: '5px',
    marginTop: '-11px',
    height: '4px',
    zIndex: '42',
    position: 'relative',
  },
};

const AppLogoBar = () => {
  const isMobile = useSelector(getIsMobile);
  return (
    <Box>
      <AppBar position="sticky">
        <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <img src={txDotLogoSrc} alt="TxDOT logo" style={styles.txDotLogo} />
          { isMobile
          && (
            <div style={styles.dtLogoWrapper}>
              <div style={styles.dtLogoWrapperInner}>
                <img src={dtLogoSrc} alt="DriveTexas logo" style={styles.logo} />
                <div style={styles.cover} />
              </div>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default AppLogoBar;
