import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { setFloodLayer } from '../utils/map-google/mapUtils';
import { getMaplarge } from '../utils/maplarge/setup-maplarge';
import parseUrl from '../utils/parse-url';
import { setLinksMenuOpen, setResourceMenuOpen } from './resources';
import { getIsMobile } from './app';

const initialState = {
  // whether toggle should exist
  floodingLikelyAvailable: true,
  // whether flooding toggle is checked
  floodingLikely: false,
  // whether conditions section is open
  open: true,
  // whether showing future conditions
  showFuture: false,
  current: { A: true, Z: true, C: true, D: true, F: true, I: true, O: true, X: true, Y: true, L: true, N: true },
  future: { A: true, Z: true, C: true, D: true, F: true, I: true, O: true },
  visable: [],
  futureVisable: [],
  date: 0,
  ...parseUrl().conditions,
};

const conditionRouter = createSlice({
  name: 'conditions',
  initialState,
  reducers: {
    setConditionsMenuOpen(state, { payload }) {
      state.open = payload;
    },
    toggleCondition: {
      reducer(state, { payload: { condition, value } }) {
        const layers = getMaplarge();
        layers.toggleLayer(condition, value);
        state.current[condition] = value;
      },
      prepare(condition, value) {
        return {
          payload: {
            condition,
            value,
          },
        };
      },
    },
    showFuture(state) {
      const layers = getMaplarge();
      layers.switchTo('future');
      setFloodLayer(false);
      state.showFuture = true;
    },
    showCurrent(state) {
      const layers = getMaplarge();
      if (layers) {
        layers.switchTo('current');
      }
      setFloodLayer(state.floodingLikely);
      state.showFuture = false;
    },
    setRefreshData: {
      prepare({ types, date }, id) {
        return {
          payload: { types, date, id },
        };
      },
      reducer(state, { payload: { types, date, id } }) {
        if (id === 'current') {
          state.visable = types;
        } else if (id === 'future') {
          state.futureVisable = types;
        }
        if (date > state.date) {
          state.date = date;
        }
      },
    },
    setConditionLayer(state, payload) {
      state.layer = payload;
    },
    toggleFuture: {
      reducer(state, { payload: { condition, value } }) {
        const layers = getMaplarge();
        layers.toggleLayer(condition, value);
        state.future[condition] = value;
      },
      prepare(condition, value) {
        return {
          payload: {
            condition,
            value,
          },
        };
      },
    },
    setFloodingLikelyAvailable(state, { payload }) {
      state.floodingLikelyAvailable = payload;
    },
    toggleFloodingLikely(state, { payload }) {
      setFloodLayer(payload);
      state.floodingLikely = payload;
    },
  },
});

export default conditionRouter.reducer;

// TODO: ask Calvin if this is even doing anything?
export const loadConditionLayer = createAction('conditions/loadConditionLayer');

export const {
  setConditionsMenuOpen,
  toggleCondition,
  toggleFuture,
  showCurrent,
  showFuture,
  setRefreshData,
  setConditionLayer,
  setFloodingLikelyAvailable,
  toggleFloodingLikely,
} = conditionRouter.actions;

// export const getConditionMenuOpen = (state) => state.conditions.open;
export const getConditionMenuOpen = (state) => state.conditions.open;
export const getCondition = (condition) => (state) => state.conditions.current[condition];
export const getConditions = (state) => state.conditions.current;

export const getFuture = (condition) => (state) => state.future[condition];
export const getFutureConditions = (state) => state.conditions.future;

export const getIsFuture = (state) => state.conditions.showFuture;

export const getVisable = (state) => state.conditions.visable;
export const getLayerVisable = (condition) => (state) => state.conditions.visable.indexOf(condition) > -1;
export const getDate = (state) => state.conditions.date;
export const getFutureVisable = (state) => state.conditions.futureVisable;
export const getConditionLayer = (state) => state.conditions.layer;

// flooding likely selectors
export const getFloodingLikely = (state) => state.conditions.floodingLikely;
export const getFloodingLikelyAvailable = (state) => state.conditions.floodingLikelyAvailable;

export const toggleConditionsMenu = createAsyncThunk('conditions/toggleConditionsMenu', (payload, thunkAPI) => {
  thunkAPI.dispatch(setConditionsMenuOpen(payload));
  // if opening menu, close other sections
  const isMobile = getIsMobile(thunkAPI.getState());
  if (payload && isMobile) {
    thunkAPI.dispatch(setResourceMenuOpen(false));
    thunkAPI.dispatch(setLinksMenuOpen(false));
  }
});
