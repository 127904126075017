const directionalIcon = 'https://dl.dropboxusercontent.com/s/10kpnvrtpxbtcb0/directional.png';
const entranceIcon = 'https://dl.dropboxusercontent.com/s/scm7jatrkenlyx3/entrance-marker.png';
const exitIcon = 'https://dl.dropboxusercontent.com/s/pthl9br3mw6pno5/exit-marker.png';
const merge = (a, b) => ({ ...b, ...a });
const baseContraStyle = {
  // Directional
  angleOffset: '0',
  antiAliasing: 'true',
  blending: 'true',
  drawLine: 'true',
  endCap: 'Round',
  //fillColor: '255-62-67-115',
  iconSpacing: 40,
  lineOffset: '0',
  startCap: 'Round',
  url: directionalIcon,
  width: '10',
};

const defaultRules = {
  contraflow: {
    styles: [{
      range: [0, 22],
      rules: [
        {
          style: { ...baseContraStyle,
            fillColor: '255-62-67-115',
          },
          where: 'CatchAll',
        },
      ],
    }],
  },

  accesspoints: {
    styles: [{
      range: [12, 22],
      rules: [{
        style: {
          borderColorOffset: '75',
          dropShadow: 'false',
          fillColor: '204-86-86-255',
          shape: 'round',
          url: entranceIcon,
          height: 21,
          width: 15,
        },
        where: [
          [
            {
              col: 'ramp',
              test: 'Contains',
              value: 'ENT',
            },
          ],
        ],
      }, {
        style: {
          borderColorOffset: '75',
          dropShadow: 'false',
          fillColor: '204-86-86-255',
          shape: 'round',
          url: exitIcon,
          height: 21,
          width: 15,
        },
        where: [
          [
            {
              col: 'ramp',
              test: 'Contains',
              value: 'EXIT',
            },
          ],
        ],
      }],
    }],
  },
};

export function dimOtherEvaculanes(route) {
  return {
    styles: [{
      range: [0, 22],
      rules: [{
        style: {
          antiAliasing: 'true',
          blending: 'true',
          endCap: 'Round',
          fillColor: '255-255-16-107',
          lineOffset: '5',
          startCap: 'Round',
          width: '4',
        },
        where: {
          col: 'routeid',
          test: 'Equal',
          value: route,
        },
      }],
    }],
  };
}

export function dimOtherRoutes(route) {
  const baseLower = { ...baseContraStyle,
    ...{
      width: 14,
      iconSpacing: 25,
    } };

  return {
    styles: [{
      range: [0, 11],
      rules: [
        {
          style: merge({
            fillColor: '255-57-65-115',
          }, baseContraStyle),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '1',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-144-166-129',
          }, baseContraStyle),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '2',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-49-134-156',
          }, baseContraStyle),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '3',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-255-166-123',
          }, baseContraStyle),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '4',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
      ],
    }, {
      range: [12, 22],
      rules: [
        {
          style: merge({
            fillColor: '255-57-65-115',
          }, baseLower),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '1',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-144-166-129',
          }, baseLower),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '2',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-49-134-156',
          }, baseLower),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '3',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
        {
          style: merge({
            fillColor: '255-255-166-123',
          }, baseLower),
          where: [[{
            col: 'phase',
            test: 'Equal',
            value: '4',
          }, {
            col: 'routeid',
            test: 'Equal',
            value: route,
          }]],
        },
      ],
    }],
  };
}

export function enableRamps(routeId, rampTypes) {
  if (rampTypes.length === 0) {
    return {
      styles: [{
        range: [0, 22],
        rules: {
          style: {
            height: 0,
            width: 0,
            shape: 'round',
            url: '',
          },
          where: [{
            col: 'routeid',
            test: 'Equal',
            value: 'fake',
          }],
        },
      }],
    };
  }
  const styles = {
    ENT: {
      borderColorOffset: '75',
      dropShadow: 'false',
      fillColor: '204-86-86-255',
      shape: 'round',
      url: entranceIcon,
    },
    EXIT: {
      borderColorOffset: '75',
      dropShadow: 'false',
      fillColor: '204-86-86-255',
      shape: 'round',
      url: exitIcon,
    },
  };

  const rules = rampTypes.map((key) => ({
    style: styles[key],
    where: [{
      col: 'routeid',
      test: 'Equal',
      value: routeId,
    }, {
      col: 'ramp',
      test: 'Equal',
      value: key,
    }],
  }));

  return {
    styles: [{
      range: [12, 22],
      rules,
    }, {
      range: [0, 11],
      rules: {
        style: {
          height: 0,
          width: 0,
          url: '',
        },
        where: 'CatchAll',
      },
    }],
  };
}

export default defaultRules;
