import { useSelector, useDispatch } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { getIsContraflowSectionOpen, toggleOpen, setRoute, getRoute } from '../../reducers/contraflow';
import { toggleRoute, toggleEvaculane } from '../../utils/maplarge/contraflow';
import SidebarSection from './SidebarSection';
import { routes } from '../../config/contraflow';
import ContraTestRoute from './ContraTestRoute';
import Tooltip from './Tooltip';

const styles = {
  list: { display: 'block',
    float: 'left',
    marginRight: '1%',
    width: '49%' },
  text: { fontSize: '12px', color: '#1976d2', textAlign: 'center' },
  icon: {},
};
export default () => {
  const dispatch = useDispatch();
  const isContraflowSectionOpen = useSelector(getIsContraflowSectionOpen);
  const route = useSelector(getRoute);
  if (route) {
    return <ContraTestRoute />;
  }
  return (
    <SidebarSection
      text={<>Contraflow Routes<Tooltip text="Select route from list or map for more detail." /></>}
      open={isContraflowSectionOpen}
      onChange={() => dispatch(toggleOpen())}
    >
      <List>
        {routes.map(({ name, bounds, id }) => (
          <ListItem style={styles.list} disablePadding key={name}>
            <ListItemButton onClick={() => {
              dispatch(setRoute(name));
              toggleRoute(id, bounds);

              toggleEvaculane(id);
            }}
            >
              <ListItemText primaryTypographyProps={{ sx: styles.text }} primary={name} />
            </ListItemButton>
          </ListItem>
        ))}

      </List>
    </SidebarSection>
  );
};
