import drinkingwater from '../assets/amenities/drinkingwater.gif';
import handicapaccess from '../assets/amenities/handicapaccess.gif';
import information from '../assets/amenities/information.gif';
import interpretive from '../assets/amenities/interpretive.gif';
import picnictable from '../assets/amenities/picnictable.gif';
import restrooms from '../assets/amenities/restrooms.gif';
import restrooms_family from '../assets/amenities/restrooms_family.gif';
import stormshelter from '../assets/amenities/stormshelter.gif';
import wireless from '../assets/amenities/wireless.gif';
import dump_station_30px from '../assets/amenities/dump_station_30px.png';

const list = {};
list['Drinking Water'] = drinkingwater;
list['Handicap Access'] = handicapaccess;
list['*'] = information;
list['Interpretive Displays'] = interpretive;
list['Picnic Tables'] = picnictable;
list['Men\'s and Women\'s Restrooms'] = restrooms;
list['2 sets of Men\'s and Women\'s Restrooms'] = restrooms;
list['Family/Assisted Restroom'] = restrooms_family;
list['Storm Shelter'] = stormshelter;
list['Wireless Internet Access'] = wireless;
list['Dump Station'] = dump_station_30px;

export default list;
