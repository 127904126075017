/** creates custom popup. */
export function createCustomInfoWindow(map) {
  class Popup extends window.google.maps.OverlayView {
    position;

    containerDiv;

    constructor(position, content) {
      // console.log('CONSTRUCTOR');
      super();
      this.position = position;
      this.map = map;
      this.show = false;
      this.setMap(map);
      this.buffer = 10;
      this.opening = false;
      content.classList.add('popup-bubble');

      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement('div');
      bubbleAnchor.classList.add('popup-bubble-anchor');
      bubbleAnchor.appendChild(content);

      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement('div');
      this.containerDiv.classList.add('popup-container');
      this.containerDiv.appendChild(bubbleAnchor);

      // Optionally stop clicks, etc., from bubbling up to the map.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      // console.log('ON ADD');
      this.getPanes().floatPane.appendChild(this.containerDiv);
    }

    /** Called when the popup is removed from the map. */
    onRemove() {
      // console.log('ON REMOVE');
      this.containerDiv.style.display = 'none';
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      // console.log('DRAW');
      if (!this.getProjection) return;

      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position,
      );

      // account for height of infowindow
      // divPosition.y -= 300;

      // Hide the popup when it is far out of view.
      const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
        ? 'block'
        : 'none';

      if (display === 'block') {
        this.containerDiv.style.left = `${divPosition.x}px`;
        this.containerDiv.style.top = `${divPosition.y}px`;

        // have to pause until infowindow can actually be detected in correct location
        setTimeout(() => {
          // if just opened new infowindow, check for overlaps with sidebar, header, and left map edge
          if (this.opening) {
            // console.log('CHECKING FOR OVERLAPS');
            this.repositionMapIfOverlapping();
            this.opening = false;
          }
        });
      }

      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
      if (!this.show) {
        this.containerDiv.style.display = 'none';
      }
    }

    repositionMapIfOverlapping() {
      // get all coordinates needs to detect overlaps
      const { left: mapLeftCoord, top: mapTopCoord } = map.getDiv().getBoundingClientRect();
      const infowindowDiv = document.getElementById('custom-infowindow');
      const infowindowBounds = infowindowDiv.getBoundingClientRect();
      let { left: infoLeftCoord, right: infoRightCoord, top: infoTopCoord } = infowindowBounds;

      let xDeltaTotal = 0;
      let yDeltaTotal = 0;

      // check if infowindow is colliding with left edge of map
      if (infoLeftCoord < mapLeftCoord) {
        console.group('OVERLAP: map left edge');
        console.log('BEFORE - info left:', infoLeftCoord);
        const xDelta = infoLeftCoord - mapLeftCoord;
        xDeltaTotal += xDelta;
        // reassign info window top coordinate to where it will be after this y adjustment
        infoLeftCoord = mapLeftCoord;
        console.log('xDelta:', xDelta);
        console.log('xDeltaTotal:', xDeltaTotal);
        console.log('AFTER - info left:', infoLeftCoord);
        console.groupEnd();
      }

      // check if infowindow is colliding with custom map controls
      const customMapControlsEl = document.getElementById('custom-map-controls');
      if (customMapControlsEl) {
        const { right: customMapControlsRightCoord } = customMapControlsEl.getBoundingClientRect();
        if (infoLeftCoord < customMapControlsRightCoord) {
          // console.group('OVERLAP: custom map controls');
          // console.log('BEFORE - info left:', infoLeftCoord);
          const xDelta = infoLeftCoord - customMapControlsRightCoord;
          xDeltaTotal += xDelta;
          // reassing info window left coordinate to where it will be after this x adjustment
          infoLeftCoord = customMapControlsRightCoord;
          // console.log('xDelta:', xDelta);
          // console.log('xDeltaTotal:', xDeltaTotal);
          // console.log('AFTER - info left:', infoLeftCoord);
          console.groupEnd();
        }
      }

      // check if infowindow is colliding with header
      if (infoTopCoord < mapTopCoord) {
        // console.group('OVERLAP: header');
        // console.log('BEFORE - info top:', infoTopCoord);
        // console.log('mapTopCoord:', mapTopCoord);
        const yDelta = infoTopCoord - mapTopCoord;
        yDeltaTotal += yDelta;
        // reassign info window top coordinate to where it will be after this y adjustment
        infoTopCoord = mapTopCoord;
        // console.log('yDelta:', yDelta);
        // console.log('yDeltaTotal:', yDeltaTotal);
        // console.log('AFTER - info top:', infoTopCoord);
        console.groupEnd();
      }

      // check for overlaps with map type controls
      const mapTypeControlsEl = document.querySelector('.gmnoprint[role="menubar"]');
      if (mapTypeControlsEl) {
        const { bottom: mapTypeControlBottomCoord, right: mapTypeControlsRightCoord } = mapTypeControlsEl.getBoundingClientRect();
        if (infoTopCoord < mapTypeControlBottomCoord && infoLeftCoord < mapTypeControlsRightCoord) {
          // console.group('OVERLAP: map type controls');
          // console.log('BEFORE - info top:', infoTopCoord);
          // console.log('mapTypeControlBottomCoord:', mapTypeControlBottomCoord);
          const yDelta = infoTopCoord - mapTypeControlBottomCoord;
          yDeltaTotal += yDelta;
          // reassign info window top coordinate to where it will be after this y adjustment
          infoTopCoord = mapTypeControlBottomCoord;
          // console.log('yDelta:', yDelta);
          // console.log('yDeltaTotal:', yDeltaTotal);
          // console.log('AFTER - info top:', infoTopCoord);
          // console.groupEnd();
        }
      }

      // check for overlaps with right sidebar
      const sidebarDiv = document.getElementById('right-sidebar');
      if (sidebarDiv) {
        const { left: sidebarLeftCoord } = sidebarDiv.getBoundingClientRect();
        // check if infowindow is colliding with sidebar
        if (infoRightCoord > sidebarLeftCoord) {
          // console.group('OVERLAP: sidebar');
          // console.log('BEFORE - info right:', infoRightCoord);
          const xDelta = infoRightCoord - sidebarLeftCoord;
          xDeltaTotal += xDelta;
          // reassign info window right coordinate to where it will be after this x adjustment
          infoRightCoord = sidebarLeftCoord;
          // console.log('xDelta:', xDelta);
          // console.log('xDeltaTotal:', xDeltaTotal);
          // console.log('AFTER - info right:', infoRightCoord);
          console.groupEnd();
        }
      }

      // if there is an adjustment in the x axis, add buffer to x adjustment, depending on adjustment direction
      if (xDeltaTotal) {
        xDeltaTotal += xDeltaTotal > 0 ? this.buffer : -this.buffer;
      }

      // if there is an adjustment in the y axis, add buffer to y adjustment, depending on adjustment direction
      if (yDeltaTotal) {
        yDeltaTotal += yDeltaTotal > 0 ? this.buffer : -this.buffer;
      }

      // pan map by x and y deltas (plus added buffers)
      map.panBy(xDeltaTotal, yDeltaTotal);

      // reset flag to stop checking for collisions
      this.opening = false;
    }

    setPosition(position) {
      // console.log('SET POSITION');
      this.position = position;
      this.draw();
    }

    open() {
      // console.log('OPEN');
      this.show = true;
      this.opening = true;
      this.containerDiv.style.display = 'block';
    }

    close() {
      // console.log('CLOSE');
      this.show = false;
      this.containerDiv.style.display = 'none';
    }
  }

  return new Popup(
    new window.google.maps.LatLng(),
    document.getElementById('custom-infowindow'),
  );
}

window.initMap = createCustomInfoWindow;
