import { useSelector, useDispatch } from 'react-redux';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';

import { getZoom, getMapDiv } from '../../reducers/map';
import { toggleCondition, getCondition, getVisable, getLayerVisable } from '../../reducers/conditions';
import { getPrintMode } from '../../reducers/print';
import layerType from '../../config/evacuation';
import ContraToggle from './ContraToggle';

const evaculaneTooltip = 'Officially designated highway shoulder which can be opened to traffic as an additional travel lane.';
const contraTooltip = 'Reversal of travel direction on inbound lanes of a highway so that all lanes carry traffic away from the affected area.';
const styles = {
  outline: {
    border: '3px solid #F75D3B !important',
    position: 'relative',
    display: 'block',
  },
  accessPointsWrapper: {
    position: 'relative',
  },
  overlay: {
    backgroundColor: '#eee',
    opacity: 0.9,
    position: 'absolute',
    inset: 0,
    zIndex: 2,
    textAlign: 'center',
    fontSize: '1.2em',
  },
};
const ZoomMessage = () => {
  const zoom = useSelector(getZoom);
  const entVisable = useSelector(getLayerVisable('N'));
  const exitVisable = useSelector(getLayerVisable('L'));
  const activated = entVisable || exitVisable;
  const printMode = useSelector(getPrintMode);
  if (zoom >= 13 || !activated || printMode) return null;

  return <Button disabled sx={styles.overlay}>Zoom In To See Access Points</Button>;
};

const Toggle = ({ id, tooltip, keyName }) => {
  const dispatch = useDispatch();
  const key = keyName;
  const active = useSelector(getLayerVisable(key));
  const status = useSelector(getCondition(key));
  if (!active) return null;

  const onChange = () => dispatch(toggleCondition(key, !status));
  return <ContraToggle id={id} checked={status} onChange={onChange} tooltip={tooltip} />;
};

const ExitToggles = () => {
  const zoom = useSelector(getZoom);
  const printMode = useSelector(getPrintMode);
  if (zoom < 13 && printMode) return null;

  return (
    <div style={styles.accessPointsWrapper}>
      {layerType.filter(({ hide }) => !hide).map(({ id, key, help }) => <Toggle keyName={key} key={id} id={id} tooltip={help} />)}
      <ZoomMessage />
    </div>
  );
};

const Contraflow = () => {
  const visable = useSelector(getVisable);
  const mapDiv = useSelector(getMapDiv);

  const contraOrEvacActive = visable.some((item) => item === 'X' || item === 'Y' || item === 'N' || item === 'L');
  if (!mapDiv || !contraOrEvacActive) {
    return <></>;
  }
  return (
    <Card sx={styles.outline}>
      <FormGroup>
        <ExitToggles />
        <Toggle id="contraflow" keyName="Y" tooltip={contraTooltip} />
        <Toggle id="evaculane" keyName="X" tooltip={evaculaneTooltip} />
      </FormGroup>
    </Card>
  );
};

export default Contraflow;
