function bindEvents(context, target, eventMap, data) {
  if (!context || !eventMap || !target) {
    return;
  }

  Object.keys(eventMap).forEach((key) => {
    const event = eventMap[key];

    if (event) {
      target.addListener(key, event.bind(context, target, data));
    }
  });
}

class MarkerGroup {
  constructor(markers, icon, eventMap) {
    this.markers = markers;
    this.icon = icon;
    this.events = eventMap;

    this.setIcon(icon);
    this.bindEvents();
  }

  setMap(map) {
    if (!this.markers) {
      return;
    }

    this.markers.forEach((marker) => {
      marker.setMap(map);
    });
    this.disableInfoWindow();
  }

  setIcon(icon) {
    this.markers.forEach((marker) => {
      marker.setIcon(icon);
    });
  }

  disableInfoWindow() {
    if (this.iw) {
      this.iw.close();
    }
  }

  invoke(method, args) {
    if (!this.markers || !method) {
      return;
    }

    this.markers.forEach((marker) => marker[method](...args));
  }

  bindEvents() {
    const self = this;
    const { events } = this;

    this.eachMarker((marker) => {
      bindEvents(self, marker, events, marker.rawData);
      delete marker.rawData;
    });
  }

  eachMarker(cb) {
    if (!this.markers || !cb) {
      return;
    }

    this.markers.forEach(cb);
  }
}

export default MarkerGroup;
