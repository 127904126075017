import { useSelector, useDispatch } from 'react-redux';

import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import { getLinksMenu, toggleLinksMenu } from '../../reducers/resources';
import SidebarSection from './SidebarSection';
import { activate, deactivate, getContraflowStatus } from '../../reducers/contraflow';
import { create, remove, toggleEvaculane } from '../../utils/maplarge/contraflow';
import externalLinks from '../../config/external-links';
import { openContraflow } from '../../reducers/info';

const styles = {
  linkRoot: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  innerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
  },
  linkTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    fontSize: '12px',
  },
  img: {
    width: '20px',
  },
  iconTextAnchor: {
    display: 'flex',
    gap: '5px',
  },
  tooltipIcon: {
    width: '16px',
    opacity: '0.6',
  },
};

const LinkText = ({ text, imgSrc, Icon, tooltip, url }) => (
  <div style={styles.linkTextWrapper}>
    {Icon && <Icon />}
    {imgSrc && <img alt={text} src={imgSrc} style={styles.img} />}
    <a href={url} rel="noopener noreferrer" target="_blank" style={styles.iconTextAnchor}>
      <Typography variant="body2">{text}</Typography>
    </a>
    {tooltip && (
      <Tooltip title={tooltip} enterTouchDelay={0}>
        <HelpIcon sx={styles.tooltipIcon} tabIndex="0" />
      </Tooltip>
    )}
  </div>
);

const Link = ({ text, url, imgSrc, Icon, help }) => (
  <div style={styles.linkRoot}>
    <div style={styles.innerWrapper}>
      <LinkText text={text} imgSrc={imgSrc} Icon={Icon} tooltip={help} url={url} />
      <a href={url} rel="noopener noreferrer" target="_blank" aria-hidden="true">
        <OpenInNewIcon />
      </a>
    </div>
  </div>
);

const ExternalLinks = () => {
  const dispatch = useDispatch();
  const linksMenuOpen = useSelector(getLinksMenu);
  const onAccordionClick = () => dispatch(toggleLinksMenu(!linksMenuOpen));
  const isContraflowOpen = useSelector(getContraflowStatus);
  const contraflow = externalLinks.find(({ text }) => text === 'Contraflow Planning');

  if (isContraflowOpen) {
    return (
      <SidebarSection
        text="External Links"
        open={linksMenuOpen}
        onChange={onAccordionClick}
      >
        <FormGroup>
          <div style={styles.linkRoot}>
            <div style={styles.innerWrapper}>
              <Button onClick={() => {
                dispatch(deactivate());
                toggleEvaculane();
                remove();
              }}
              >
                <div style={styles.linkTextWrapper}>
                  <img alt={contraflow.text} src={contraflow.imgSrc} style={styles.img} />
                  <Typography variant="body2">return to map</Typography>
                </div>

              </Button>
            </div>
          </div>
        </FormGroup>

      </SidebarSection>
    );
  }
  return (
    <SidebarSection
      text="External Links"
      open={linksMenuOpen}
      onChange={onAccordionClick}
    >
      <FormGroup>
        {externalLinks.filter(({ text }) => text !== 'Contraflow Planning').map((props) => <Link key={props.text} {...props} />)}
        <div style={styles.linkRoot}>
          <div style={styles.innerWrapper}>
            <Button onClick={() => {
              dispatch(activate());
              create();
              dispatch(openContraflow());
            }}
            >
              <div style={styles.linkTextWrapper}>
                <img alt={contraflow.text} src={contraflow.imgSrc} style={styles.img} />
                <Typography variant="body2">{contraflow.text}</Typography>
              </div>

            </Button>
          </div>
        </div>
      </FormGroup>
    </SidebarSection>
  );
};

export default ExternalLinks;
