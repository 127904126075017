import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

const styles = {
  tooltipIcon: {
    width: '16px',
    opacity: '0.6',
  } };
export default ({ text }) => (
  <Tooltip
    title={text}
    enterTouchDelay={0}
  >
    <HelpIcon sx={styles.tooltipIcon} tabIndex="0" />
  </Tooltip>
);
