import { useSelector, useDispatch } from 'react-redux';

import FormGroup from '@mui/material/FormGroup';
import { conditions } from '../../../config';
import { getFutureVisable, getIsFuture, toggleFuture, getFutureConditions } from '../../../reducers/conditions';
import { getShowingFuture } from '../../../reducers/info';
import DisabledSection from './DisabledSection';
import { getIsPreview, getPreviewShowing } from '../../../reducers/app';

import SidebarToggle from '../SidebarToggle';

const skip = new Set(['X', 'Y']);
const FutureConditions = () => {
  const dispatch = useDispatch();
  const conditionStatus = useSelector(getFutureConditions);
  const isPreview = useSelector(getIsPreview);
  const previewShowing = useSelector(getPreviewShowing);
  const isFuture = useSelector(getIsFuture);
  const visable = useSelector(getFutureVisable);
  let showing = useSelector(getShowingFuture);
  if (isPreview && !isNaN(previewShowing) && previewShowing !== undefined) {
    showing = previewShowing;
  }
  if (!isFuture) return null;

  let visableConditions = conditions.filter(({ key }) => !skip.has(key));
  if (visable.length) {
    visableConditions = visableConditions.filter(({ key }) => visable.indexOf(key) > -1);
  }
  const onChange = (id) => (event) => dispatch(toggleFuture(id, event.target.checked));

  return (
    <FormGroup
      role="tabpanel"
      id="future-tabpanel"
      aria-labelledby="future-tab"
    >
      {visableConditions.map(({ id, name, key, imgSrc, help }) => (
        <SidebarToggle
          key={id}
          name={name}
          checked={conditionStatus[key]}
          onChange={onChange(key)}
          imgSrc={imgSrc}
          tooltip={help}
        />
      ))}
      <DisabledSection show={showing} />
    </FormGroup>
  );
};

export default FutureConditions;
