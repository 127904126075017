const maskPath = 'https://storage.googleapis.com/drivetexas/mask';
const blankUrl = 'https://maps.gstatic.com/mapfiles/transparent.png';

export default (opacity) => {
  const { google } = window;
  return new google.maps.ImageMapType({
    opacity: opacity || 1,
    tileSize: new google.maps.Size(256, 256),
    getTileUrl: (coord, zoom) => {
      if (zoom > 9) {
        return blankUrl;
      }
      return `${[maskPath, zoom, coord.x, coord.y].join('/')}.png`;
    },
  });
};
