import React from 'react';
import { useDispatch } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import contraflowExample from '../../assets/contraflow-example.png';
import evaculaneExample from '../../assets/evaculane-example.png';

import { setClosed } from '../../reducers/info';
import BootstrapDialogTitle from '../utility/BootstrapDialogTitle';

const styles = {
  image: { width: '100%' },
};

export default function ContraFaq() {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(setClosed());

  return (
    <div>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Emergency Planning
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          <p>
            TxDOT’s DriveTexas™ Emergency Planning website provides evacuation route
            information for the State of Texas, with potential Contraflow and Evaculane locations
            and access points. This site is for planning purposes only and does not display live
            information. In the event that Contraflow or Evaculanes are activated, they will be
            displayed as current conditions on <a href="drivetexas.org" target="_blank">www.DriveTexas.org</a>.
          </p>

          <h4>What is Contraflow?</h4>
          <p>
            <img src={contraflowExample} alt="example showing how contraflow works by both sides of the highway going in the same direction" style={styles.image} />
            Contraflow is the reversal of traffic on the highway so both sides of
            the highway carry traffic in a single direction. Contraflow will only
            be activated if the area is threatened by a major hurricane and
            mandatory evacuations are issued. The decision to reverse the direction
            of traffic will be made by local authorities working with the State
            Operations Center based on the strength of the storm and projected landfall.
          </p>

          <h4>What are Contraflow Phases?</h4>
          <p>
            In order to maximize safety and traffic flow efficiency,
            Contraflow along a highway is activated in phases.
            On the emergency planning site, these phases are numbered in the
            order that they will be activated. If activated, they will be
            displayed as current Contraflow conditions on <a href="drivetexas.org" target="_blank">www.DriveTexas.org</a>.
          </p>

          <h4>What are Contraflow Access Points?</h4>
          <p>
            Access points are existing highway entrance and exit ramps which can
            be activated as points of access to the Contraflow lanes of a highway.
            These do not include regular entrance and exit ramps to the normal travel lanes.
            Not all ramps may be activated as access points. If activated, access points
            will be displayed along with the current Contraflow conditions on <a href="drivetexas.org" target="_blank">www.DriveTexas.org</a>.
          </p>

          <h4>What are Evaculanes?</h4>
          <p>
            <img src={evaculaneExample} alt="example showing evaculanes are an additional lane marked by hurricane icon that can be used" style={styles.image} />
            An Evaculane is a specially improved outside highway shoulder which can
            be opened to traffic as an additional travel lane. Evaculanes may
            be activated as part of the separate Contraflow plan or on their own,
            based on the decision by local authorities in coordination with the
            State Operations Center.
          </p>

          <h4>When can I drive on the Evaculane?</h4>
          <p>
            Activating the Evaculane requires performing a sweep
            to clear the shoulder of debris, stalled vehicles, and other obstacles.
            Though it may appear to be clear, you should not enter the
            Evaculane until it has been opened by state forces.
            If the Evaculane is activated, it will be displayed as a
            current Evaculane condition on <a href="drivetexas.org" target="_blank">www.DriveTexas.org</a>.
          </p>

          <h4>What are Comfort Stations?</h4>
          <p>
            Comfort Stations may be activated at Safety Rest Area locations along
            evacuation routes. All activated Comfort Stations will have oxygen units,
            drinking water, and ice available. Pet support may also be available at selected locations.
          </p>

          <h4>What should I do in the event of an emergency?</h4>
          <p>
            If state or local officials order a mandatory evacuation, leave immediately.
            Be sure your home is secure, and take your pets with you.
            Do not wait for Contraflow or Evaculanes to be activated.
            <br /><br />
            If you are elderly or disabled and will need evacuation assistance,
            please call 2-1-1 to register for a ride in case of an emergency.
            Your registration will not expire.
            <br /><br />
            General guidelines for hurricane evacuation, including emergency supply
            checklists and evacuation route maps, is posted at<>{' '}</>
            <a
              href="http://www.txdot.gov/driver/weather/hurricane.html"
              target="_blank"
              rel="noreferrer"
            >
              http://www.txdot.gov/driver/weather/hurricane.html
            </a>
            <br /><br />
            During active state emergency operations, additional information can
            be found at<>{' '}</>
            <a
              href="http://emergency.portal.texas.gov/en/Pages/Home.aspx"
              target="_blank"
              rel="noreferrer"
            >
              http://emergency.portal.texas.gov/en/Pages/Home.aspx
            </a>.
          </p>
        </Typography>
        <Divider />

      </DialogContent>

    </div>
  );
}
