// action type constants
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setTrafficUtil, createMap, runDirectionSearch, cancelRendering, getPopup, getMap } from '../utils/map-google/mapUtils';
import parseUrl from '../utils/parse-url';
import { getPrintMode } from './print';
import { setClosed, setInfoLoc } from './infowindow';
import { getIsMobile } from './app';
import { placesDetailsRequest } from '../thunks/places';

const mapDefaults = parseUrl().map;

const initialState = {
  mapDiv: '',
  traffic: true,
  latLng: mapDefaults.center || { lat: 0, lng: 0 },
  zoom: mapDefaults.zoom || 0,
  origin: mapDefaults.origin || null,
  originQuery: mapDefaults.originLabel || '',
  originLabel: mapDefaults.originLabel || '',
  originOptions: [],
  destination: mapDefaults.destination || null,
  destinationQuery: mapDefaults.destinationLabel || '',
  destinationLabel: mapDefaults.destinationLabel || '',
  destinationOptions: [],
  directionMode: false,
  directions: null,
  // tracks whether old format URL was used to load site
  fromHash: mapDefaults.fromHash,
};

const slice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapDiv(state, { payload }) {
      state.mapDiv = payload;
    },
    setOriginDetails(state, { payload }) {
      state.origin = { placeId: payload.placeId };
      state.originLabel = payload.label;
    },
    setDestinationDetails(state, { payload }) {
      state.destination = { placeId: payload.placeId };
      state.destinationLabel = payload.label;
    },
    setDirectionsMode(state, { payload }) {
      state.directionMode = payload;
    },
    setTraffic(state, { payload }) {
      if (state.traffic === payload) {
        return;
      }
      setTrafficUtil(payload);
      state.traffic = payload;
    },
    setLatLng(state, { payload }) {
      state.latLng = payload;
    },
    setZoom(state, { payload }) {
      state.zoom = payload;
    },
    setDirections(state, { payload }) {
      state.directions = payload;
    },
    swapDirections(state) {
      const { origin, originLabel, originQuery } = state;
      state.origin = state.destination;
      state.originQuery = state.destinationQuery;
      state.originLabel = state.destinationLabel;
      state.destination = origin;
      state.destinationQuery = originQuery;
      state.destinationLabel = originLabel;
    },
    cancelDirectionsLocal(state) {
      state.originQuery = '';
      state.originLabel = null;
      state.directionMode = false;
      state.destinationLabel = null;
      state.directions = null;
      state.origin = null;
      state.destination = null;
      state.destinationQuery = '';
      cancelRendering();
    },
    setPlacesAutocompleteOptions(state, { payload: { locationType, options } }) {
      if (locationType === 'origin') {
        state.originOptions = options;
      } else {
        state.destinationOptions = options;
      }
    },
    setPlacesAutocompleteQuery(state, { payload: { locationType, query } }) {
      if (locationType === 'origin') {
        state.originQuery = query;
        if (!query) state.origin = null;
      } else {
        state.destinationQuery = query;
        if (!query) state.destination = null;
      }
    },
  },
});

export const {
  cancelDirectionsLocal,
  setLocalSearch,
  setDirections,
  setMapDiv,
  setLatLng,
  setZoom,
  setOriginDetails,
  setDestinationDetails,
  setDirectionsMode,
  swapDirections,
  setPlacesAutocompleteOptions,
  setPlacesAutocompleteQuery,
} = slice.actions;

export const cancelDirections = createAsyncThunk('map/cancelDirections', async (arg, thunkAPI) => {
  thunkAPI.dispatch(setClosed());
  thunkAPI.dispatch(cancelDirectionsLocal());
});

export const runDirections = createAsyncThunk('map/runDirections', async (args, thunkAPI) => {
  const state = thunkAPI.getState();
  const { origin, destination } = state.map;
  const isPrintMode = getPrintMode(state);
  const { distance, duration, midpoint } = await runDirectionSearch({ origin, destination, isPrintMode });

  thunkAPI.dispatch(setDirections({ distance, duration }));
  thunkAPI.dispatch(setInfoLoc(midpoint));

  const isMobile = getIsMobile(thunkAPI.getState());
  if (!isMobile) {
    const popup = getPopup();
    const map = getMap();
    // trigger built in overlap detection + map repositioning once map down moving
    window.google.maps.event.addListenerOnce(map, 'idle', () => popup.open());
  } else {
    thunkAPI.dispatch(setClosed());
  }
});

export const setupMapServices = createAsyncThunk('map/setupMapServices', async (arg, thunkAPI) => {
  const mapDiv = document.getElementById('map');
  mapDiv.toJSON = () => '<mapdiv>';
  mapDiv.toString = () => '<mapdiv>';
  const print = getPrintMode(thunkAPI.getState());
  await createMap({ print });
  // set map reference in redux store
  thunkAPI.dispatch(setMapDiv(mapDiv));
  const { origin, destination, originLabel } = thunkAPI.getState().map;
  if (origin && !destination) {
    thunkAPI.dispatch(placesDetailsRequest({ place_id: origin.placeId, description: originLabel }));
  } else if (origin && destination) {
    thunkAPI.dispatch(runDirections());
  }
});

export const { setTraffic } = slice.actions;
export const getMapDiv = (state) => state.map.mapDiv;
export const getTraffic = (state) => state.map.traffic;
export const getOrigin = (state) => state.map.origin;
export const getZoom = (state) => state.map.zoom;
export const getDirectionMode = (state) => state.map.directionMode;
export const getDestination = (state) => state.map.destination;
export const getOriginLabel = (state) => state.map.originLabel;
export const getDestinationLabel = (state) => state.map.destinationLabel;
export const getDirections = (state) => state.map.directions;
export const getPlacesAutocompleteOptions = (locationType) => (state) => (locationType === 'origin' ? state.map.originOptions : state.map.destinationOptions);
export const getPlacesAutocompleteQuery = (locationType) => (state) => (locationType === 'origin' ? state.map.originQuery : state.map.destinationQuery);
export const getUrlHashState = (state) => state.map.fromHash;

// reducer
export default slice.reducer;
