import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import { useSelector, useDispatch } from 'react-redux';

import { setDirectionsMode, getOriginLabel } from '../../reducers/map';

export const SearchLocationWindow = () => {
  const label = useSelector(getOriginLabel);
  const dispatch = useDispatch();
  return (
    <Stack direction="row" spacing={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      <IconButton aria-label="Get Directions" onClick={() => dispatch(setDirectionsMode(true))}>
        <DriveEtaIcon />
      </IconButton>
      <Typography variant="body1">{label}</Typography>
    </Stack>
  );
};
