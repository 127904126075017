import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setView } from '../utils/map-google/mapUtils';
import { showCurrent, toggleCondition, toggleFuture } from './conditions';
import { setClosed } from './infowindow';

const { REACT_APP_STATIC_GOOGLE_HOST, REACT_APP_CLOUD_STORAGE_BUCKET } = process.env;
const initialState = {
  open: true,
  modalName: 'intro',
  info: {
    working: true,
    splashscreen: 'TxDOT is committed to your safety and to the reliability of the information contained on this site. While road conditions can change rapidly, DriveTexas.org is an industry leader in providing some of the most accurate and up-to-date travel-related information currently available to drivers in Texas. Information presented here is as close to real time as possible. For those who use our roads, please do not use this site while operating a motor vehicle. Be Safe. Drive Smart.',
    showing: 3,
    deactivated: [],
    date: 1659201835953,
  },
  fullWidth: false,
};

const infoReducer = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setInfo(state, { payload }) {
      state.info = payload;
    },

    openFAQ(state) {
      state.modalName = 'FAQ';
      state.open = true;
    },
    openCFAQ(state) {
      state.modalName = 'cfaq';
      state.open = true;
    },
    openPrintModal(state) {
      state.modalName = 'printModal';
      state.open = true;
    },
    openIntroModal(state) {
      state.modalName = 'intro';
      state.open = true;
    },
    openInfoWindowModal(state) {
      state.modalName = 'InfoWindowModal';
      state.open = true;
      state.fullWidth = true;
    },
    openContraflow(state) {
      state.modalName = 'Contraflow';
      state.open = true;
    },
  },
  extraReducers: (builder) => builder.addCase(setClosed, (state) => {
    state.open = false;
    state.fullWidth = false;
  }),
});

export const { openCFAQ, setInfo, openIntroModal, openFAQ, openPrintModal, openInfoWindowModal, openContraflow } = infoReducer.actions;
export { setClosed };
export default infoReducer.reducer;
export const getWorking = (state) => state.info.info.working;
export const getSplashscreen = (state) => state.info.info.splashscreen;
export const getShowing = (state) => state.info.info.showing;
export const getShowingFuture = (state) => state.info.info.showing === 3;
export const getShowingCurrent = (state) => state.info.info.showing >= 2;
export const getDeactivated = (state) => state.info.info.deactivated;
export const getDate = (state) => state.info.info.date;
export const getOpen = (state) => state.info.open;
export const getFullwidth = (state) => state.info.fullWidth;

const redirectToStatic = (where) => {
  if (
    where === 'gcp' // if the redirect says gcp
    && REACT_APP_STATIC_GOOGLE_HOST // and we have a redirect url
  ) {
    window.location.replace(REACT_APP_STATIC_GOOGLE_HOST);
    return;
  }
  // if static is enabled and things are weird, we can default to
  // redirecting to the old amazon one.
  window.location.replace('http://alerts.drivetexas.org/');
};
export const getModalName = (state) => state.info.modalName;

export const fetchInfo = createAsyncThunk('info/fetchInfo', async (arg, thunkAPI) => {
  const req = await fetch(`https://storage.googleapis.com/${REACT_APP_CLOUD_STORAGE_BUCKET}/info.json`);
  if (!req.ok) {
    return;
  }
  const info = await req.json();
  // set map reference in redux store
  if (info.redirect && !window._preventRedirect) {
    console.log('Redirect via config `working` flag');
    return redirectToStatic(info.redirect);
  }
  thunkAPI.dispatch(setInfo(info));
  if (info.showing === 2) {
    const { conditions: { showFuture } } = thunkAPI.getState();
    if (showFuture) {
      thunkAPI.dispatch(showCurrent());
    }
  }
  if (info.deactivated.length) {
    info.deactivated.forEach((condition) => {
      thunkAPI.dispatch(toggleCondition(condition, false));
      thunkAPI.dispatch(toggleFuture(condition, false));
    });
  }
});

export const locateUser = createAsyncThunk('info/locateUser', async (arg, thunkAPI) => {
  thunkAPI.dispatch(setClosed());
  global.navigator.geolocation.getCurrentPosition((pos) => {
    setView(pos.coords.latitude, pos.coords.longitude, 12);
  });
});
