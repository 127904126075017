import enterImgSrc from '../assets/symbology/enter.png';
import exitImgSrc from '../assets/symbology/exit.png';
import contraflowImgSrc from '../assets/symbology/contraflow.png';
import evaculaneImgSrc from '../assets/symbology/evaculane.png';

const evacuation = [
  {
    id: 'accessEnt',
    name: 'Contraflow Entrance',
    key: 'N',
    imgSrc: enterImgSrc,
    altIcon: 'images/symbology/enter.png',
    enabled: undefined,
    disabledByUser: false,
    zoomDisable: true,
    help: 'Existing highway ramp converted to entrance ramp onto Contraflow lanes. Ramps on the normal travel lanes are not affected.',
  }, {
    id: 'accessExt',
    name: 'Contraflow Exit',
    key: 'L',
    imgSrc: exitImgSrc,
    altIcon: 'images/symbology/exit.png',
    enabled: undefined,
    disabledByUser: false,
    zoomDisable: true,
    help: 'Existing highway ramp converted to exit ramp off of Contraflow lanes. Ramps on the normal travel lanes are not affected.',
  }, {
    id: 'contraflow',
    name: 'Contraflow',
    key: 'Y',
    imgSrc: contraflowImgSrc,
    altIcon: 'images/symbology/contraflow.png',
    lineIcon: true,
    enabled: true,
    disabledByUser: false,
    hide: true,
    help: 'Reversal of travel direction on inbound lanes of a highway so that all lanes carry traffic away from the affected area.',
  }, {
    id: 'evaculane',
    name: 'Evaculane',
    key: 'X',
    imgSrc: evaculaneImgSrc,
    altIcon: 'images/symbology/evaculane.png',
    lineIcon: true,
    enabled: undefined,
    disabledByUser: false,
    hide: true,
    help: 'Officially designated highway shoulder which can be opened to traffic as an additional travel lane.',
  },
];

export default evacuation;
