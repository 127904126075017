import React from 'react';
import { useDispatch } from 'react-redux';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import { setClosed } from '../../reducers/info';
import faqs from '../../config/faqdata.json';
import BootstrapDialogTitle from '../utility/BootstrapDialogTitle';

const FAQs = ({ faqs }) => (
  <>
    <List>
      {faqs.questions.map(({ question, answer, Component }) => (
        <ListItem key={question} disablePadding>
          <ListItemText
            primary={question}
            secondary={Component ? <Component /> : answer}
            primaryTypographyProps={{ variant: 'h6', component: 'h2' }}
          />
        </ListItem>
      ))}
    </List>

    <Typography variant="h6" component="h2">Links</Typography>
    <ul>
      {faqs.links.map(({ url, text }) => (
        <li key={text}>
          <a target="_blank" rel="noreferrer" href={url}>{text}</a>
        </li>
      ))}
    </ul>
  </>
);

export default function FAQmodal() {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setClosed());
  };

  return (
    <>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Frequently Asked Questions
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <FAQs faqs={faqs} />
      </DialogContent>
    </>
  );
}
