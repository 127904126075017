import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'contraflow',
  initialState: {
    active: false,
    open: true,
    route: false,
    evaculane: false,
    exit: true,
    ent: true,
  },
  reducers: {
    activate: (state) => {
      state.active = true;
    },
    deactivate: (state) => {
      state.active = false;
      state.route = false;
      state.evaculane = false;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    toggleEvaculaneStatus: (state) => {
      state.evaculane = !state.evaculane;
    },
    setRoute: (state, { payload }) => {
      state.route = payload;
      state.evaculane = true;
      state.exit = true;
      state.ent = true;
    },
    toggleExitStatus(state) {
      state.exit = !state.exit;
    },
    toggleEntStatus(state) {
      state.ent = !state.ent;
    },

  },
});

export const { toggleExitStatus, toggleEntStatus, activate, deactivate, toggleOpen, setRoute, toggleEvaculaneStatus } = appSlice.actions;

export const getContraflowStatus = (state) => state.contraflow.active;
export const getIsContraflowSectionOpen = (state) => state.contraflow.open;
export const getRoute = (state) => state.contraflow.route;
export const getIsEvaculaneVisable = (state) => state.contraflow.evaculane;
export const getAreEntVis = (state) => state.contraflow.ent;
export const getAreExitVis = (state) => state.contraflow.exit;
export default appSlice.reducer;
