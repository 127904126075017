import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, ListItemIcon, ListItem, ListItemText, ListItemButton } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HelpIcon from '@mui/icons-material/Help';
import UpdateIcon from '@mui/icons-material/Update';
import CallIcon from '@mui/icons-material/Call';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { getSplashscreen, setClosed, locateUser, openFAQ } from '../../reducers/info';
import { getDate } from '../../reducers/conditions';
import { formatDate } from '../../utils/formatDate';
import BootstrapDialogTitle from '../utility/BootstrapDialogTitle';
import { getIsPreview, getPreviewSplashscreen } from '../../reducers/app';
import { getMap } from '../../utils/map-google/mapUtils';
import config from '../../config';

const { txStateBounds } = config;

const ShowDate = () => {
  const ms = useSelector(getDate);
  return (
    <Typography variant="body2">
      Data last updated on {formatDate(ms, true)}.
    </Typography>
  );
};

const FAQ = (props) => (
  <ListItem disablePadding>
    <ListItemButton onClick={props.handleOpenFAQ} disableGutters>
      <ListItemIcon>
        <HelpIcon />
      </ListItemIcon>
      <ListItemText
        primary={(
          <Typography variant="body2" sx={{ textDecoration: 'underline', color: 'blue' }}>
            Frequently Asked Questions
          </Typography>
        )}
      />
    </ListItemButton>
  </ListItem>
);

const ContactUs = () => (
  <ListItem disablePadding>
    <ListItemIcon>
      <CallIcon />
    </ListItemIcon>
    <ListItemText
      primary={(
        <Typography variant="body2">
          Contact us at <a href="tel:+1-800-452-9292">+1-800-452-9292</a>
        </Typography>
      )}
    />
  </ListItem>
);

const LastUpdate = () => (
  <ListItem disablePadding>
    <ListItemIcon>
      <UpdateIcon />
    </ListItemIcon>
    <ListItemText primary={
      <ShowDate />
    }
    />
  </ListItem>
);

export default function IntroModal() {
  const dispatch = useDispatch();
  let splashscreen = useSelector(getSplashscreen);
  const isPreview = useSelector(getIsPreview);
  const previewSplashscreen = useSelector(getPreviewSplashscreen);
  if (isPreview && previewSplashscreen) {
    splashscreen = previewSplashscreen;
  }
  const handleClose = () => dispatch(setClosed());
  const handleOpenFAQ = () => dispatch(openFAQ('FAQ'));
  const handleZoomToStateExtent = () => {
    const map = getMap();
    map.fitBounds(txStateBounds);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Welcome to DriveTexas
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {splashscreen}
        </Typography>
        <Divider />
        <List dense>
          <FAQ handleOpenFAQ={handleOpenFAQ} />
          <LastUpdate />
          <ContactUs />
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={() => dispatch(locateUser())}>
          My Location
        </Button>
        <Button variant="contained" onClick={handleZoomToStateExtent}>
          Default Location
        </Button>
      </DialogActions>
    </div>
  );
}
