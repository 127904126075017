import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfoWindow from './InfoWindow/InfoWindow';
import { setupMapServices, getMapDiv } from '../reducers/map';
import { loadConditionLayer, getConditionLayer, setConditionLayer } from '../reducers/conditions';
import { fetchInfo, getDeactivated, getShowing } from '../reducers/info';
import { createMaplarge, setDeactivated, updateGroups } from '../utils/maplarge/setup-maplarge';
import { getMap, resetMapView } from '../utils/map-google/mapUtils';
import { useAppbarHeight } from '../hooks/useAppBarHeight';
import { getIsPreview, getPreviewDisabled, getPreviewShowing } from '../reducers/app';
import { getContraflowStatus } from '../reducers/contraflow';

const createStyles = ({ appBarHeight, contraflowBarHeight }) => ({
  mapWrapper: {
    height: `calc(100vh - ${appBarHeight + contraflowBarHeight}px)`,
    width: '100%',
  },
});

const MapPanel = () => {
  const mapDiv = useSelector(getMapDiv);
  const conditions = useSelector(getConditionLayer);
  let deactivated = useSelector(getDeactivated);
  const isPreview = useSelector(getIsPreview);
  const isContraflowOpen = useSelector(getContraflowStatus);

  const previewDisabed = useSelector(getPreviewDisabled);
  if (isPreview && Array.isArray(previewDisabed)) {
    deactivated = previewDisabed;
  }
  const dispatch = useDispatch();
  // reset map view when unmounting map
  useEffect(() => resetMapView, []);

  useEffect(() => {
    setDeactivated(deactivated);
  }, [deactivated, conditions]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchInfo());
    }, 1000 * 60 * 5 /* 5 minutes*/);
    dispatch(fetchInfo());

    return () => {
      clearInterval(interval);
    };
  }, []);

  // init map on first mount, otherwise append mapDiv to component div
  useEffect(() => {
    if (!mapDiv) dispatch(setupMapServices());
    else {
      mapDiv.style.display = 'block';
      mapDiv.style.height = '100%';
      document.getElementById('map-wrapper').appendChild(mapDiv);
      const map = getMap();
      if (map.someLeafletProp) map.invalidateSize();
      if (!conditions) {
        dispatch(setConditionLayer(createMaplarge()));
      }
    }
    return () => {
      if (mapDiv?.style?.display) mapDiv.style.display = 'none';
      // cleanup height property from above
      if (mapDiv?.style?.height) mapDiv.style.height = null;
    };
  }, [mapDiv, setupMapServices, conditions, loadConditionLayer]);

  const previewShowing = useSelector(getPreviewShowing);
  let showPanels = useSelector(getShowing);
  if (isPreview && !isNaN(previewShowing) && previewShowing !== undefined) {
    showPanels = previewShowing;
  }
  useEffect(() => {
    if (!mapDiv || !conditions) {
      return;
    }
    updateGroups(showPanels);
  }, [showPanels, mapDiv, conditions]);
  const appBarHeight = useAppbarHeight();
  const contraflowBarHeight = isContraflowOpen ? 40 : 0;
  const styles = createStyles({ appBarHeight, contraflowBarHeight });
  return (
    <>
      <InfoWindow />
      <div
        id="map-wrapper"
        style={styles.mapWrapper}
      />
    </>
  );
};

export default MapPanel;
