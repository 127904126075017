import { useSelector, useDispatch } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import SidebarToggle from './SidebarToggle';
import { toggleExitStatus, toggleEntStatus, getAreEntVis, getAreExitVis, getIsContraflowSectionOpen, toggleOpen, setRoute, getRoute, getIsEvaculaneVisable, toggleEvaculaneStatus } from '../../reducers/contraflow';
import { resetRoute, toggleEvaculane, setRamps } from '../../utils/maplarge/contraflow';
import SidebarSection from './SidebarSection';
import { routes } from '../../config/contraflow';
import enterenceImg from '../../assets/symbology/enter.png';
import exitImg from '../../assets/symbology/exit.png';
import { getZoom } from '../../reducers/map';
import evaculaneImg from '../../assets/symbology/evaculane.png';

const getRouteConfig = (inName) => routes.find(({ name }) => name === inName);
const styles = {
  img: {
    verticalAlign: 'middle',
    width: '45px',
  },
  titleText: { fontSize: '1.17em' },
  evaculaneIcon: {
    color: '#FF106B',
    fontSize: '1.33333333em;',
    display: 'inline-block',
    lineHeight: '0.75em',
  },
  accessPointsWrapper: {
    position: 'relative',
  },
  overlay: {
    backgroundColor: '#eee',
    opacity: 0.9,
    position: 'absolute',
    inset: 0,
    zIndex: 2,
    textAlign: 'center',
    fontSize: '1.2em',
  },
  list: {
    display: 'block',
    float: 'left',
    marginRight: '1%',
    width: '32%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  text: { fontSize: '12px', textAlign: 'center' },

};
const colors = {
  1: '#394173',
  2: '#90A681',
  3: '#31869C',
  4: '#FFA67B',
};
const ZoomMessage = () => {
  const zoom = useSelector(getZoom);
  if (zoom >= 12) return null;

  return <Button disabled sx={styles.overlay}>Zoom In To See Access Points</Button>;
};
export default () => {
  const dispatch = useDispatch();
  const isContraflowSectionOpen = useSelector(getIsContraflowSectionOpen);
  const isEvaculaneVisable = useSelector(getIsEvaculaneVisable);
  const route = useSelector(getRoute);
  const routeConfig = getRouteConfig(route);
  const areEntVis = useSelector(getAreEntVis);
  const areExitVis = useSelector(getAreExitVis);
  return (

    <SidebarSection
      text="Contraflow Routes"
      open={isContraflowSectionOpen}
      onChange={() => dispatch(toggleOpen())}
    >
      <List>
        <ListItem>
          <ListItemText primary={<><img style={styles.img} alt="shield" src={routeConfig.shield} /><span style={styles.titleText}>Route {route}</span></>} />
        </ListItem>
        <ListItem>
          <List>{routeConfig.phases.map((number) => <ListItem style={styles.list} key={number}>   <ListItemText primaryTypographyProps={{ sx: styles.text }}><CircleIcon sx={{ color: colors[number], size: '80%' }} /> Phase: {number}</ListItemText></ListItem>)}</List>
        </ListItem>
        <ListItem>
          <SidebarToggle
            name="Evaculane"
            checked={isEvaculaneVisable}
            imgSrc={evaculaneImg}
            tooltip="Officially designated highway shoulder which can be opened to traffic as an additional travel lane."
            //   imgSrc={imgSrc}
            onChange={() => {
              dispatch(toggleEvaculaneStatus());
              if (isEvaculaneVisable) {
                toggleEvaculane();
              } else {
                toggleEvaculane(routeConfig.id);
              }
            }}
          />
        </ListItem>
        <div style={styles.accessPointsWrapper}>
          <ZoomMessage />
          <ListItem>
            <SidebarToggle
              name="Entrances"
              checked={areEntVis}
              tooltip="Existing highway ramp converted to entrance ramp onto Contraflow lanes. Ramps on the normal travel lanes are not affected."
              //   Icon={Icon}
              imgSrc={enterenceImg}
              onChange={() => {
                dispatch(toggleEntStatus());
                setRamps();
              }}
            />
          </ListItem>

          <ListItem>
            <SidebarToggle
              name="Exits"
              checked={areExitVis}
              //   Icon={Icon}
              tooltip="Existing highway ramp converted to exit ramp off of Contraflow lanes. Ramps on the normal travel lanes are not affected."
              imgSrc={exitImg}
              onChange={() => {
                dispatch(toggleExitStatus());
                setRamps();
              }}
            />
          </ListItem>
        </div>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              dispatch(setRoute());
              resetRoute();
              toggleEvaculane();
            }}
            sx={{ color: '#1976d2', textAlign: 'center' }}
          >
            <ListItemText primary="View All Routes" />
          </ListItemButton>
        </ListItem>
      </List>
    </SidebarSection>
  );
};
