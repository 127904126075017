import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Map from './components/map';
import Modal from './components/Modals/modal';
import Sidebar from './components/Sidebar/Sidebar';
import ControlContainer from './components/ControlContainer/ControlContainer';
import { getPrintMode } from './reducers/print';
import AppLogoBar from './components/AppLogoBar';
import ContraflowBar from './components/ContraflowBar';
import { getSidebarOpen, setIsMobile, setSidebarOpen } from './reducers/app';
import { getUrlHashState } from './reducers/map';
import { openSnackbar } from './reducers/snackbar';
import { getContraflowStatus } from './reducers/contraflow';
import SnackbarMessage from './components/Snackbar';

function App() {
  const printStatus = useSelector(getPrintMode);
  const sidebarOpen = useSelector(getSidebarOpen);
  const isContraflowOpen = useSelector(getContraflowStatus);
  const appWasLoadedFromHash = useSelector(getUrlHashState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsMobile(isMobile));
  }, [isMobile]);

  useEffect(() => {
    if (isMobile && sidebarOpen) {
      dispatch(setSidebarOpen(false));
    }
    // show warning message if app was loaded from an old format hashed URL
    if (appWasLoadedFromHash) {
      dispatch(openSnackbar({
        message: 'Some settings have changed within DriveTexas.org. If you have arrived here from a bookmark, you may need to update this to match your previous settings.',
        severity: 'warning',
        autoHideDuration: null,
      }));
    }
  }, []);

  return (
    <div className="App">
      <SnackbarMessage />
      { !printStatus && <AppLogoBar />}
      { !printStatus && <Modal />}
      { isContraflowOpen && <ContraflowBar />}
      <Map />
      <Sidebar />
      { !printStatus && <ControlContainer />}

    </div>
  );
}

export default App;
