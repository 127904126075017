import { useSelector, useDispatch } from 'react-redux';

import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import { Button } from '@mui/material';
import { getDate } from '../../reducers/conditions';
import { openFAQ, openCFAQ } from '../../reducers/info';

import { formatDate } from '../../utils/formatDate';
import dtLogoSrc from '../../assets/drive-texas.svg';
import { getIsMobile } from '../../reducers/app';
import { getPrintMode } from '../../reducers/print';
import { getContraflowStatus } from '../../reducers/contraflow';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: '5px',
    gap: '5px',
  },
  logo: {
    padding: '10px',
  },
  logoMobile: {
    display: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '0',
    padding: '0',
    textTransform: 'none',
  },
  updatedStatus: {
    padding: '0 10px 5px 10px ',
  },
};

const SidebarHeader = () => {
  const isMobile = useSelector(getIsMobile);
  const printMode = useSelector(getPrintMode);
  const isContraflowOpen = useSelector(getContraflowStatus);

  const dispatch = useDispatch();
  const date = useSelector(getDate);
  const handleOpenFAQ = () => {
    dispatch(openFAQ('FAQ'));
  };
  const handleOpenContraFAQ = () => {
    dispatch(openCFAQ('FAQ'));
  };
  const formattedDate = formatDate(date, true);

  return (
    <div id="sidebar-header" style={isMobile ? styles.rootMobile : styles.root}>

      <img src={dtLogoSrc} alt="DriveTexas Logo" style={isMobile ? styles.logoMobile : styles.logo} />

      {!printMode && <Button component="button" size={isMobile ? 'small' : 'medium'} startIcon={isMobile ? null : <HelpIcon />} onClick={handleOpenFAQ} sx={styles.button} aria-label="Frequently Asked Question ">Frequently Asked Questions</Button>}
      {(!printMode && isContraflowOpen) && <Button component="button" size={isMobile ? 'small' : 'medium'} startIcon={isMobile ? null : <HelpIcon />} onClick={handleOpenContraFAQ} sx={styles.button} aria-label="Frequently Asked Question ">Contraflow FAQ</Button>}
      <Typography variant={isMobile ? 'caption' : 'body2'} sx={styles.updatedStatus}>Data Updated: {formattedDate}</Typography>
    </div>
  );
};

export default SidebarHeader;
