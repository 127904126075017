import { Loader } from '@googlemaps/js-api-loader';
import midpoint from 'midpoint';
import TileLayer from 'maplarge-google';
import store from '../../store';
import config from '../../config';
import createMapOptions from './createMapOptions';
import createResourceLayers from './resources';
import { styles } from '../rules/flood';
import { setFloodContent, setBothContent, setOpen, setMaplargeContent } from '../../reducers/infowindow';
import { setFloodingLikelyAvailable } from '../../reducers/conditions';
import { setLatLng, setZoom } from '../../reducers/map';
import { getMaplarge } from '../maplarge/setup-maplarge';
import maskMapType from './mask';
import parseUrl from '../parse-url';
import { createCustomInfoWindow } from './customInfoWindow';

const { REACT_APP_ML_HOST, REACT_APP_SUBDOMAINS } = process.env;

const findMidpoint = (arr) => {
  const fixed = arr.map((item) => {
    const { lat, lng } = item.toJSON();
    return [lng, lat];
  });
  const [lng, lat] = midpoint(fixed);
  return { lng, lat };
};

let map;
let popup;
let trafficLayer;
let layers;
let floodLayer;
let directionsRenderer;
let directionsService;
let marker;
let polyline;
let markerOnMap = false;
const { google: { mapOptions } } = config;

const initFloodLayer = () => {
  floodLayer = new TileLayer({
    account: 'appgeo',
    host: REACT_APP_ML_HOST,
    refresh: 30,
    subdomains: REACT_APP_SUBDOMAINS,
    table: 'floodPoint',
    dissolve: 'Radius',
    rules: { styles },
    zindex: 3,
    fields: [],
    name: 'flood',
    async click(event) {
      const maplarge = getMaplarge();
      const results = await maplarge.onClick({ latLng: event.latLng }, true);
      // console.log('results', results);
      if (results && results.length) {
        store.dispatch(setBothContent(event.latLng, event.data, results, Date.now()));
      } else {
        store.dispatch(setFloodContent(event.latLng, event.data));
      }
    },
  });
  floodLayer.on('fullTable', async () => {
    try {
      const results = await floodLayer.getFields('lastUpdated');
      const floodingActive = !!results && results.length;
      store.dispatch(setFloodingLikelyAvailable(floodingActive));
    } catch (e) {
      store.dispatch(setFloodingLikelyAvailable(false));
    }
  });
};
export const resetMapView = () => map.setOptions(mapOptions);

export const getMap = () => map;
export const setTrafficUtil = (on) => {
  if (on) {
    trafficLayer.setMap(map);
  } else {
    trafficLayer.setMap(null);
  }
};
export const setFloodLayer = (on) => {
  if (!TileLayer) {
    initFloodLayer();
  }
  if (on) {
    floodLayer.setMap(map);
  } else {
    floodLayer.setMap(null);
  }
};
export const initResourceLayers = () => {
  layers = createResourceLayers();
};

export const setLayer = (layerName, on) => {
  if (!layers) {
    initResourceLayers();
  }
  const layer = layers[layerName];
  if (!layer) {
    throw new Error(`unknown layer ${layerName}`);
  }
  if (on) {
    layer.setMap(map);
  } else {
    layer.setMap(null);
  }
};

const initResources = () => {
  const { resources: { traffic, restAreas, infoCenters, cameras } } = store.getState();
  initFloodLayer();
  if (traffic) {
    setTrafficUtil(true);
  }
  if (restAreas) {
    setLayer('rest-areas', true);
  }
  if (infoCenters) {
    setLayer('information-centers', true);
  }
  if (cameras) {
    setLayer('traffic-cameras', true);
  }
};

export const setView = (lat, lng, zoom) => {
  const map = getMap();
  map.setCenter({ lat, lng });
  if (zoom) {
    map.setZoom(zoom);
  }
};

export const getPopup = () => popup;

export const createMap = async ({ print }) => {
  // const loadingMsg = 'Loading Google Maps API';
  // loading.start(loadingMsg);
  // set up Google Maps API loader

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GMAPS_API_KEY,
    libraries: ['places', 'marker'],
    version: process.env.REACT_APP_GMAPS_RELEASE_CHANNEL,
  });
  // trigger loading of Google Maps API
  await loader.load();
  const { google } = window;

  // set map options
  const mapOptions = createMapOptions({ print });
  // get map options from URL, get zoom/center from old-format hashed URL and then remove hash if present
  const { map: mapOverrides } = parseUrl({ removeHash: true });
  const options = { ...mapOptions, ...mapOverrides };
  map = new google.maps.Map(document.getElementById('map'), options);

  // little hack to get the scale bar to show up in mi/ft instead of km/m
  const scaleInterval = setInterval(() => {
    const spans = document.getElementById('map').getElementsByTagName('span');
    const pattern = /\d+\s+(m|km)/i;
    for (const i in spans) {
      if (pattern.test(spans[i].innerHTML)) {
        spans[i].click();
        clearInterval(scaleInterval);
      }
    }
  }, 500);
  // if for some reason the scale bar doesn't show up, stop trying after 10 seconds
  setTimeout(() => { clearInterval(scaleInterval); }, 10000);

  // expose map object during local development
  if (process.env.REACT_APP_LOCAL) {
    window.gmap = map;
  }

  popup = createCustomInfoWindow(map);

  // expose map object during local development
  if (process.env.REACT_APP_LOCAL) {
    window.popup = popup;
  }

  trafficLayer = new google.maps.TrafficLayer();

  // trafficLayer.setMap(map);
  initResources();
  // load flood layer if toggled on
  const floodToggled = store.getState().conditions.floodingLikely;
  if (floodToggled) setFloodLayer(true);

  map.overlayMapTypes.insertAt(0, maskMapType(0.8));
  map.addListener('zoom_changed', () => {
    store.dispatch(setZoom(map.getZoom()));
  });
  map.addListener('center_changed', () => {
    store.dispatch(setLatLng(map.getCenter().toJSON()));
  });
  // floodLayer.setMap(map);
  // loading.end(loadingMsg);
  return map;
};

const initDirections = ({ isPrintMode }) => {
  directionsRenderer = new window.google.maps.DirectionsRenderer({
    suppressMarkers: false,
    suppressPolylines: true,
    preserveViewport: !!isPrintMode,
  });
  polyline = new window.google.maps.Polyline({
    strokeColor: '#0080ff',
    strokeOpacity: 0.55,
    strokeWeight: 6,
  });
  polyline.addListener('click', (e) => {
    const maplarge = getMaplarge();
    const data = maplarge.manualClick(e);
    if (data) {
      maplarge.onClick({ latLng: e.latLng, data }, true).then((data) => {
        store.dispatch(setMaplargeContent(e.latLng, data));
      });
      return;
    }
    store.dispatch(setOpen(e.latLng));
  });
  directionsRenderer.toJSON = () => '<directionsRenderer>';
  directionsService = new window.google.maps.DirectionsService();
};
export const cancelRendering = () => {
  if (directionsRenderer) {
    directionsRenderer.setMap(null);
  }
  if (polyline) {
    polyline.setMap(null);
  }
};
export const runDirectionSearch = ({ origin, destination, isPrintMode }) => {
  if (!directionsRenderer) {
    initDirections({ isPrintMode });
  }
  return new Promise((resolve, reject) => {
    directionsService.route({
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    }, (response, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        const map = getMap();
        directionsRenderer.setMap(map);
        directionsRenderer.setDirections(response);
        polyline.setMap(map);
        polyline.setPath(response.routes[0].overview_path);
        const { distance, duration } = response.routes[0].legs[0];
        const mid = findMidpoint(response.routes[0].overview_path);
        resolve({ distance, duration, midpoint: mid });
      } else {
        reject(status);
      }
    });
  });
};

const initMarker = () => {
  marker = new window.google.maps.Marker({
    icon: {
      url: 'http://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png',
      size: new window.google.maps.Size(71, 71),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
      scaledSize: new window.google.maps.Size(35, 35),
    },
  });
};
export const setMarker = (latLng) => {
  if (!marker) {
    initMarker();
  }
  marker.setPosition(latLng);
  if (!markerOnMap) {
    marker.setMap(map);
  }
  markerOnMap = true;
};
export const rmMarker = () => {
  if (markerOnMap) {
    marker.setMap(null);
    markerOnMap = false;
  }
};
