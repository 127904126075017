import { getMap } from './mapUtils';
import config from '../../config';

let placesAutocomplete;
let autocompleteToken;
let placesService;

const { txStateBounds } = config;

const getPlacesAutocomplete = () => {
  if (!placesAutocomplete) {
    placesAutocomplete = new window.google.maps.places.AutocompleteService();
  }
  return placesAutocomplete;
};

const getAutocompleteToken = () => {
  if (!autocompleteToken) {
    autocompleteToken = new window.google.maps.places.AutocompleteSessionToken();
  }
  return autocompleteToken;
};

export const clearAutoCompleteToken = () => {
  autocompleteToken = null;
};

export const getAutocompleteResults = (query) => new Promise((resolve, reject) => {
  const autocomplete = getPlacesAutocomplete();
  const sessionToken = getAutocompleteToken();
  autocomplete.getPlacePredictions({ input: query, bounds: txStateBounds, sessionToken, componentRestrictions: { country: 'us' } }, (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      reject(status);
    }
    const validPredictions = predictions.filter(({ place_id }) => place_id);
    resolve(validPredictions);
  });
});

const getPlacesService = () => {
  if (!placesService) {
    const map = getMap();
    placesService = new window.google.maps.places.PlacesService(map);
  }
  return placesService;
};

export const getPlacesDetails = (placeId) => new Promise((resolve, reject) => {
  const service = getPlacesService();
  const sessionToken = getAutocompleteToken();
  const request = {
    placeId,
    sessionToken,
    fields: ['geometry'],
  };
  service.getDetails(request, (results, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      reject(status);
    }
    clearAutoCompleteToken();
    resolve(results);
  });
});
