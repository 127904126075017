import contraRules, { dimOtherRoutes, dimOtherEvaculanes, enableRamps } from './contra-rules';
import { getMaplarge } from './setup-maplarge';
import { getMap } from '../map-google/mapUtils'; // eslint-disable-line
import store from '../../store';
import { routes } from '../../config/contraflow';
import { setRoute, getRoute, getAreEntVis, getAreExitVis } from '../../reducers/contraflow';
import { setAccessContent } from '../../reducers/infowindow';

let rampsOn = false;
let TileLayer;
const { REACT_APP_ML_HOST, REACT_APP_SUBDOMAINS } = process.env;
const maplarge = {
  account: 'appgeo',
  host: REACT_APP_ML_HOST,
  refresh: 30,
  subdomains: REACT_APP_SUBDOMAINS,
};
const getTileLayer = () => {
  if (!TileLayer) {
        TileLayer = require('maplarge-google').default; // eslint-disable-line
  }
  return TileLayer;
};

const contraflow = {
  lines: {
    type: 'line',
    table: 'contraflow_dissolve',
    fields: ['routeid'],
    zindex: 0,
    rules: contraRules.contraflow,
    maxZoom: 22,
    minZoom: 0,
  },
  points: {
    type: 'point',
    table: 'accesspoints',
    zindex: 2,
    rules: contraRules.accesspoints,
    fields: ['routeid', 'ramp', 'route', 'accessid', 'description'],
    maxZoom: 22,
    minZoom: 12,
  },

};
let line; let
  point;
let evaculanes;

export const toggleEvaculane = (name) => {
  if (name) {
    const map = getMap();
    evaculanes.rules = dimOtherEvaculanes(name);
    evaculanes.refreshTiles();
    evaculanes.setMap(map);
  } else {
    evaculanes.setMap(null);
  }
};
export const toggleRoute = (name, bounds) => {
  const map = getMap();

  line.rules = dimOtherRoutes(name);
  line.refreshTiles();
  point.rules = enableRamps(name, ['ENT', 'EXIT']);
  point.refreshTiles();
  point.setMap(map);
  rampsOn = true;
  map.fitBounds(new window.google.maps.LatLngBounds(...bounds));
  map.setOptions({
    minZoom: 8,
    maxZoom: 17,
  });
};

const createContraflow = () => {
  const map = getMap();
  const layers = getMaplarge();
  const TileLayer = getTileLayer();
  line = new TileLayer({ ...contraflow.lines,
    ...maplarge,
    click(e) {
      const route = getRoute(store.getState());
      if (route) {
        return;
      }

      const id = parseInt(e.data.routeid, 10);
      const { name, bounds } = routes.find(({ id: _id }) => id === _id);
      store.dispatch(setRoute(name));
      toggleRoute(id, bounds);
      toggleEvaculane(id);
    } });
  point = new TileLayer({ ...contraflow.points,
    ...maplarge,
    click(e) {
      store.dispatch(setAccessContent(e.latLng, e.data, Date.now()));
    } });
  evaculanes = new TileLayer({
    ...maplarge,
    type: 'line',
    table: 'evaculanes',
    zindex: 1,
    click(e) {
      console.log('evaculane', e);
    },
    rules: undefined,
  });
  layers.deactivateCurent();
  line.setMap(map);
};
export const create = createContraflow;
export const remove = () => {
  const layers = getMaplarge();
  layers.reactivateCurrent();
  if (line) {
    line.setMap(null);
    line = null;
  }
  if (point) {
    point.setMap(null);
    rampsOn = false;
    point = null;
  }
};

export const resetRoute = () => {
  line.rules = contraRules.contraflow;
  line.refreshTiles();
  point.setMap(null);
  rampsOn = false;
  const map = getMap();
  map.setOptions({
    minZoom: undefined,
    maxZoom: undefined,
  });
};

export const setRamps = () => {
  const name = getRoute(store.getState());
  const types = [];
  if (getAreEntVis(store.getState())) {
    types.push('ENT');
  }
  if (getAreExitVis(store.getState())) {
    types.push('EXIT');
  }
  const { id } = routes.find(({ name: _name }) => name === _name);
  if (types.length > 0) {
    point.rules = enableRamps(id, types);
    if (!rampsOn) {
      rampsOn = true;
      const map = getMap();
      point.setMap(map);
    }
  } else if (rampsOn) {
    point.setMap(null);
    rampsOn = false;
  }
  point.refreshTiles();
};
