import store from '../store';

const { REACT_APP_PRINT_URL } = process.env;
const getTz = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // pass
  }
};

const openPrintModal = (title) => {
  const mapDiv = document.getElementById('map');
  const params = new URLSearchParams(document.location.search);
  params.set('width', mapDiv.clientWidth);
  params.set('height', mapDiv.clientHeight);
  params.set('title', title);
  params.set('print', true);
  const { map: { origin, originLabel, destination, destinationLabel } } = store.getState();
  if (origin && origin.placeId && originLabel) {
    params.set('origin_place_id', origin.placeId);
    params.set('origin_label', originLabel);
  }
  if (destination && destination.placeId && destinationLabel) {
    params.set('destination_place_id', destination.placeId);
    params.set('destination_label', destinationLabel);
  }
  const tz = getTz();
  if (tz) {
    params.set('tz', tz);
  }
  const fullUrl = `${REACT_APP_PRINT_URL}/pdf/print/v2?${params.toString()}`;
  window.open(fullUrl, '_blank');
};

export default openPrintModal;
