import { createSlice } from '@reduxjs/toolkit';
import parseUrl from '../utils/parse-url';

const printSlice = createSlice({
  name: 'print',
  initialState: {
    print: parseUrl().print,
    printTitle: '',
  },
  reducers: {
    printOn: (state) => {
      state.print = true;
    },
    printOff: (state) => {
      state.print = false;
    },
    setPrintTitle: (state, { payload }) => {
      state.printTitle = payload;
    },
  },
});

export const { printOn, printOff, setPrintTitle } = printSlice.actions;

export const getPrintMode = (state) => state.print.print;
export const getPrintTitle = (state) => state.print.printTitle;

export default printSlice.reducer;
