import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';

import { useEffect } from 'react';
import { GoogleMapsDirectionsButton } from './GoogleMapsDirectionsButton';

import { cancelDirections, runDirections, swapDirections, getDirections, getOrigin, getDestination } from '../../../reducers/map';
import { PlacesAutocomplete } from '../../PlacesAutocomplete';
import { getPopup } from '../../../utils/map-google/mapUtils';

const useStyles = ({ directions }) => ({
  root: {
    justifyContent: 'left',
    marginLeft: '12px',
  },
  toAndReverseWrapper: {
    position: 'absolute',
    top: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '94%',
    marginLeft: '-18px',
    zIndex: 0,
  },
  buttonsWrapper: {
    width: directions ? 'calc(100% + 10px)' : '80%',
    padding: '10px 0',
    justifyContent: directions ? 'space-evenly' : 'left',
    marginLeft: directions ? '0px' : '8px',
  },
});

const makeLabel = (directions) => {
  if (!directions) return;
  const { distance, duration } = directions;
  return `${duration.text} (${distance.text})`;
};

export const Directions = () => {
  const dispatch = useDispatch();
  const origin = useSelector(getOrigin);
  const destination = useSelector(getDestination);
  const directions = useSelector(getDirections);
  const styles = useStyles({ directions });

  const handleCancelDirections = () => dispatch(cancelDirections());

  const timeLabel = makeLabel(directions);

  // very hacky fix to trying to get infowindow to reposition itself
  useEffect(() => {
    const popup = getPopup();
    setTimeout(popup.draw, 1000);
  }, []);

  return (
    <Grid container spacing={1} paddingTop sx={styles.root}>
      <Grid item xs={10} sx={{ zIndex: 10 }}>
        <PlacesAutocomplete locationType="origin" isDirections />
      </Grid>
      <Grid item xs={12} sx={styles.toAndReverseWrapper}>
        <span>to</span>
        <IconButton onClick={() => dispatch(swapDirections())}>
          <SwapVertIcon />
        </IconButton>
      </Grid>
      <Grid item xs={10} sx={{ zIndex: 10 }}>
        <PlacesAutocomplete locationType="destination" isDirections />
      </Grid>

      {directions && (
        <Grid item xs={10} sx={{ zIndex: 10 }}>
          <Typography variant="body2" fontWeight={100} padding>
            {timeLabel}
          </Typography>
        </Grid>
      )}

      <Stack direction="row" spacing={1} justifyContent="space-around" sx={styles.buttonsWrapper}>
        <Button variant="outlined" size="small" disabled={!origin || !destination} onClick={() => dispatch(runDirections())}>ROUTE</Button>
        { directions && (
          <>
            <GoogleMapsDirectionsButton />
            <Button
              variant="contained"
              size="small"
              onClick={handleCancelDirections}
            >
              Cancel Directions
            </Button>
          </>
        )}
      </Stack>
    </Grid>
  );
};
