import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { getContraflowContent, getAccessContent } from '../../reducers/infowindow';
import ExitIcon from '../../assets/symbology/exit.png';
import EnterIcon from '../../assets/symbology/enter.png';

const styles = {
  span: {
    fontWeight: 200,
  },
};

const routes = {
  1: 'US 59/IH69',
  2: 'I-45',
  3: 'US 290',
  4: 'I-10',
  5: 'I-37',
  6: 'US 83/281/IH69E',
};

const ContraflowInfo = () => {
  const data = useSelector(getContraflowContent);
  const accessData = useSelector(getAccessContent);
  let ramp; let routeid; let description; let icon; let
    text;
  if (accessData) {
    ({ ramp, routeid, description } = accessData);
    icon = ramp === 'EXIT' ? <img src={ExitIcon} alt="Exit" /> : <img src={EnterIcon} alt="Enter" />;
    text = ramp === 'EXIT' ? 'Contraflow Exit' : 'Contraflow Entrance';
  }

  return (
    <div>
      {accessData === null
      && (
        <>
          <Typography variant="h5" gutterBottom style={{ fontSize: '20px' }}>
            Contraflow
          </Typography>
          <Typography variant="body2">
            <span style={styles.span}>Route: </span> {data && routes[data.routeid]}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span style={styles.span}>Phase: </span> {data && data.phase}
          </Typography>
          <Divider />
          <Typography variant="body2" gutterBottom>
            Contraflow is the reversal of traffic on the highway so both sides of the highway carry traffic in a single direction.
          </Typography>
        </>
      )}
      {accessData !== null && (
        <>
          <Typography variant="h5" gutterBottom style={{ fontSize: '20px' }}>
            <span>{icon}</span> {text}
          </Typography>
          <Typography variant="body2">
            <span style={styles.span}>Route: </span> {routes[routeid]}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span style={styles.span}>Cross Street: </span> {description}
          </Typography>
        </>
      )}

    </div>
  );
};

export default ContraflowInfo;
