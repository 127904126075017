import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Portal from '@mui/material/Portal';

import { getType, getOpen, getLatlng, getShowMarker, getNoZoom } from '../../reducers/infowindow';
import { openInfoWindowModal } from '../../reducers/info';
import { getMap, setMarker, getPopup, rmMarker } from '../../utils/map-google/mapUtils';
import InnerInfo from './InnerInfo';
import { getDirectionMode } from '../../reducers/map';
import { getIsMobile } from '../../reducers/app';
import { getPrintMode } from '../../reducers/print';

const OuterWindow = () => {
  const latlng = useSelector(getLatlng);
  const dispatch = useDispatch();
  const type = useSelector(getType);
  const isPrintMode = useSelector(getPrintMode);
  const showMarker = useSelector(getShowMarker);
  const directionMode = useSelector(getDirectionMode);
  const noZoom = useSelector(getNoZoom);
  const isMobile = useSelector(getIsMobile);
  const showModal = isMobile && !(type === 'search' && !directionMode);
  const popup = getPopup();

  useEffect(() => {
    const map = getMap();
    // don't use popups on mobile, use modals
    if (showModal) popup.close();
    if (type === 'search' && !noZoom && !isPrintMode) {
      map.setZoom(15);
      map.setCenter(latlng);
    }
    if (type === 'search' && showMarker && !showModal && !noZoom) {
      setMarker(latlng);
      popup.setOptions({
        pixelOffset: new window.google.maps.Size(0, -28),
      });
    } else {
      rmMarker();
      popup.setOptions({
        pixelOffset: new window.google.maps.Size(0, 0),
      });
    }
    popup.setPosition(latlng);
    if (showModal) dispatch(openInfoWindowModal('InfoWindowModal'));
    else popup.open({ map });
    // cleanup function
    return () => {
      const popup = getPopup();
      popup.close();
      rmMarker();
      popup.setOptions({
        pixelOffset: new window.google.maps.Size(0, 0),
      });
    };
  }, [latlng, type, showMarker, showModal]);

  const popupDiv = document.getElementById('custom-infowindow');
  return (
    <Portal container={popupDiv}>
      <InnerInfo />
    </Portal>
  );
};

const InfoWindow = () => {
  const open = useSelector(getOpen);

  if (open) {
    return (
      <OuterWindow />
    );
  }
  return null;
};
export default InfoWindow;
