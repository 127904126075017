import { useDispatch, useSelector } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import CurrentConditionsPanel from './CurrentConditionsPanel';
import FutureConditionsPanel from './FutureConditionsPanel';
import { getIsPreview, getPreviewShowing } from '../../../reducers/app';

import { getShowing } from '../../../reducers/info';
import { setClosed } from '../../../reducers/infowindow';
import { getPrintMode } from '../../../reducers/print';

import {
  getConditions,
  getIsFuture,
  showCurrent,
  showFuture,
  getConditionMenuOpen,
  toggleConditionsMenu,
} from '../../../reducers/conditions';
import SidebarSection from '../SidebarSection';

const a11yProps = (category) => ({
  id: `${category}-tab`,
  'aria-controls': `${category}-tabpanel`,
});

const getTabStyles = ({ toggled, left }) => {
  let boxShadow = 'unset';
  if (!toggled) {
    boxShadow = left ? 'inset -1px 1px 3px rgb(0 0 0 / 13%)' : 'inset 0 1px 3px rgb(0 0 0 / 13%)';
  }
  let borderLeft = 'unset';
  let borderRight = 'unset';
  if (!toggled) {
    if (left) {
      borderRight = '1px solid rgb(0 0 0 / 13%)';
    } else {
      borderLeft = '1px solid rgb(0 0 0 / 13%)';
    }
  }
  return {
    fontSize: '0.8rem',
    background: toggled ? '#fff' : '#F3F3F3',
    color: toggled ? '' : '#999',
    borderLeft,
    borderRight,
    borderBottom: toggled ? 'unset' : '1px solid rgb(0 0 0 / 13%)',
    boxShadow,
  };
};

const ConditionsSection = () => {
  const dispatch = useDispatch();
  const conditionStatus = useSelector(getConditions);
  const isPreview = useSelector(getIsPreview);
  const previewShowing = useSelector(getPreviewShowing);
  let showPanels = useSelector(getShowing);
  if (isPreview && !isNaN(previewShowing) && previewShowing !== undefined) {
    showPanels = previewShowing;
  }
  const showFuturePanel = showPanels === 3;
  const open = useSelector(getConditionMenuOpen);
  const handleClick = () => dispatch(toggleConditionsMenu(!open));
  const isFuture = useSelector(getIsFuture);
  let value = isFuture ? 1 : 0;
  if (!showFuturePanel) {
    value = 0;
  }
  const printStatus = useSelector(getPrintMode);

  const handleTabChange = (item, newValue) => {
    if (!showFuturePanel) {
      return;
    }
    dispatch(setClosed());
    if (newValue === 1) dispatch(showFuture());
    else dispatch(showCurrent());
  };

  if (!conditionStatus) return null;
  const conditionText = `${isFuture ? 'Future' : 'Current'} Conditions`;
  return (
    <SidebarSection
      text={conditionText}
      open={open}
      onChange={handleClick}
    >
      {!printStatus && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleTabChange}
            aria-label="Current/Future Conditions Tabs"
          >
            <Tab tabIndex={0} label="Current" {...a11yProps('current')} sx={getTabStyles({ toggled: value === 0, left: true })} />
            <Tab tabIndex={0} label={`Future${showFuturePanel ? '' : '(disabled)'}`} {...a11yProps('future')} sx={getTabStyles({ toggled: value === 1 })} />
          </Tabs>
        </Box>
      )}

      <CurrentConditionsPanel />
      {showFuturePanel && <FutureConditionsPanel />}
    </SidebarSection>
  );
};

export default ConditionsSection;
