import Switch from '@mui/material/Switch';
import { useSelector } from 'react-redux';
import SidebarToggleLabel from './SidebarToggleLabel';

import { getPrintMode } from '../../reducers/print';

const styles = {
  root: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
  },
};

const SidebarToggle = ({ name, checked, Icon, imgSrc, iconColor, tooltip, onChange, disabled = false }) => {
  const printStatus = useSelector(getPrintMode);

  if (printStatus && !checked) return null;
  const regex = /[,/ _-]/g;
  const id = `${name.toLowerCase().replace(regex, '-')}-label`;

  return (
    <div style={styles.root}>
      <div style={styles.inner}>
        <SidebarToggleLabel id={id} name={name} imgSrc={imgSrc} Icon={Icon} iconColor={iconColor} tooltip={tooltip} />
        {printStatus ? <div /> : <Switch disabled={disabled} size="small" inputProps={{ 'aria-labelledby': id }} checked={checked} onChange={onChange} />}
      </div>
    </div>
  );
};

export default SidebarToggle;
