import img1 from '../assets/signs/1.png';
import img2 from '../assets/signs/2.png';
import img3 from '../assets/signs/3.png';
import img4 from '../assets/signs/4.png';
import img5 from '../assets/signs/5.png';
import img6 from '../assets/signs/6.png';

export const routes = [
  { phases: [1], shield: img1, id: 1, name: 'US59/IH69', bounds: [{ lat: 30.192315, lng: -96.435576 }, { lat: 31.697494, lng: -92.826567 }], centroid: { lat: 31.045253811799999, lng: -94.823267714799996 } },
  { phases: [1], shield: img2, id: 2, name: 'IH45', bounds: [{ lat: 29.708027, lng: -99.040709 }, { lat: 32.709593, lng: -91.822692 }], centroid: { lat: 31.594020776899999, lng: -96.153106921700001 } },
  { phases: [1], shield: img3, id: 3, name: 'US290', bounds: [{ lat: 29.392638, lng: -98.086958 }, { lat: 30.910171, lng: -94.47795 }], centroid: { lat: 30.113981, lng: -96.073843 } },
  { phases: [1], shield: img4, id: 4, name: 'IH10', bounds: [{ lat: 28.884956, lng: -98.570872 }, { lat: 30.410182, lng: -94.961863 }], centroid: { lat: 29.650624764500002, lng: -97.511881417100000 } },
  { phases: [1, 2, 3], shield: img5, id: 5, name: 'IH37/IH77/IH69', bounds: [{ lat: 27.618441, lng: -99.552088 }, { lat: 29.162348, lng: -95.943079 }], centroid: { lat: 28.488529, lng: -98.142535 } },
  { phases: [1, 2, 3, 4], shield: img6, id: 6, name: 'US83/281/IH69E', bounds: [{ lat: 25.635227, lng: -100.967705 }, { lat: 28.756719, lng: -93.749687 }], centroid: { lat: 26.168002, lng: -97.694365 } },
];
