import DialogContent from '@mui/material/DialogContent';
import InnerInfo from '../InfoWindow/InnerInfo';

const InfoWindowModal = () => (
  <div>
    <DialogContent dividers>
      <InnerInfo />
    </DialogContent>
  </div>

);

export default InfoWindowModal;
