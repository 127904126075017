import { useSelector } from 'react-redux';
import { getZoom } from '../../reducers/map';
import layerType from '../../config/evacuation';

import { getPrintMode } from '../../reducers/print';
import SidebarToggle from './SidebarToggle';

const ContraToggle = ({ id, onChange, checked, tooltip }) => {
  const printStatus = useSelector(getPrintMode);
  const zoom = useSelector(getZoom);
  let disabled = false;

  const { name, imgSrc, zoomDisable } = layerType.find((item) => item.id === id);

  if (zoomDisable && zoom < 13) {
    disabled = true;
  }
  if (printStatus && !checked) return null;
  return (
    <SidebarToggle
      name={name}
      checked={checked}
      imgSrc={imgSrc}
      tooltip={tooltip}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default ContraToggle;
