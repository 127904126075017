import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import FloodInfo from './FloodInfo';
import MaplargeInfo from './MaplargeInfo';
import AmenitiesInfo from './Amenities';
import BothInfo from './BothInfo';
import ContraflowInfo from './ContraflowInfo';
import { Directions } from './directions/Directions';
import { SearchLocationWindow } from './SearchLocationWindow';

import { getType, setClosed } from '../../reducers/infowindow';
import { getDirectionMode } from '../../reducers/map';

import { getPopup } from '../../utils/map-google/mapUtils';

const Content = () => {
  const type = useSelector(getType);
  const directonMode = useSelector(getDirectionMode);
  switch (type) {
    case 'flood':
      return <FloodInfo />;
    case 'maplarge':
      return <MaplargeInfo />;
    case 'infocenter':
      return <AmenitiesInfo isInfoCenter />;
    case 'restarea':
      return <AmenitiesInfo />;
    case 'both':
      return <BothInfo />;
    case 'contraflow':
      return <ContraflowInfo />;
    case 'search':
      if (directonMode) return <Directions />;
      return <SearchLocationWindow />;
    default:
      return null;
  }
};

const PopupContent = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setClosed());
    const popup = getPopup();
    popup.close();
  };

  return (
    <>
      <div style={{ marginRight: '30px' }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Content />
      </div>
    </>
  );
};

export default PopupContent;
