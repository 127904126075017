const entranceIcon = 'https://storage.googleapis.com/drivetexas/symbology/entrance-marker.png';
const exitIcon = 'https://storage.googleapis.com/drivetexas/symbology/exit-marker.png';
const rules = [
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/accident18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'A',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/closed18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'Z',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/other18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'O',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/damage18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'D',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/icesnow18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'I',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '204-86-86-255',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/flooding18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'F',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/construction18px.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'C',
        },
      ],
    ],
  },
  {
    style: {
      shape: 'round',
      fillColor: '255-255-16-107',
      borderColorOffset: '75',
      text: {
        color: '255-255-255-255',
      },
      dropShadow: 'false',
      url: 'https://storage.googleapis.com/drivetexas/symbology/evaculane-dark.png',
    },
    where: [
      [
        {
          col: 'CNSTRNTTYPECD',
          test: 'Equal',
          value: 'X',
        },
      ],
    ],
  },

];
const exitRules = [{
  style: {
    shape: 'round',
    fillColor: '255-255-16-107',
    borderColorOffset: '75',
    text: {
      color: '255-255-255-255',
    },
    dropShadow: 'false',
    url: entranceIcon,
  },
  where: [
    [
      {
        col: 'CNSTRNTTYPECD',
        test: 'Equal',
        value: 'N',
      },
    ],
  ],
},
{
  style: {
    shape: 'round',
    fillColor: '255-255-16-107',
    borderColorOffset: '75',
    text: {
      color: '255-255-255-255',
    },
    dropShadow: 'false',
    url: exitIcon,
  },
  where: [
    [
      {
        col: 'CNSTRNTTYPECD',
        test: 'Equal',
        value: 'L',
      },
    ],
  ],
}];
export const styles = [{
  range: [13, 20],
  rules: rules.concat(exitRules),
  query: [{
    col: 'line',
    test: 'EqualNot',
    value: 1,
  }],
}, {
  range: [10, 12],
  rules,
  query: [{
    col: 'line',
    test: 'EqualNot',
    value: 1,
  }],
}, {
  range: [0, 9],
  rules,
}];
