import { toggleFloodingLikely } from '../reducers/conditions';

export default [
  {
    id: 'flood',
    name: 'Flooding Likely',
    handleToggle: toggleFloodingLikely,
    iconColor: '#1d89bc',
    help: 'Data Available in Select Coastal Areas',
  },
];
