const hashRegex = /^#\/(\d?\d)\/(-?\d{1,3}\.\d+)\/(-?\d{1,3}\.\d+)\?/;
const getDefaultLocation = (removeHash) => {
  const out = {};
  if (!document.location.hash || document.location.hash.length < 2) {
    return out;
  }
  const match = hashRegex.exec(document.location.hash);
  if (!match) {
    return out;
  }
  out.zoom = parseInt(match[1], 10);
  out.center = {
    lat: parseFloat(match[2]),
    lng: parseFloat(match[3]),
  };
  out.fromHash = true;

  // remove hash from URL(done specifically when map is being initialized so it can have access to zoom/center)
  if (removeHash) {
    const url = window.location.origin + window.location.search;
    history.replaceState({}, document.title, url);
  }

  return out;
};

const parseConditions = (params) => {
  const out = {
    open: true,
    showFuture: false,
    current: {
      A: true,
      Z: true,
      C: true,
      D: true,
      F: true,
      I: true,
      O: true,
      X: true,
      Y: true,
      L: true,
      N: true,
    },
    future: {
      A: true,
      Z: true,
      C: true,
      D: true,
      F: true,
      I: true,
      O: true,
    },
    visable: [],
    futureVisable: [],
    date: 0,
  };
  if (params.get('v') === 'f') {
    out.showFuture = true;
  }
  if (params.has('ch')) {
    const rawHidden = params.get('ch');
    for (const letter of rawHidden) {
      if (out.current[letter.toUpperCase()]) {
        out.current[letter.toUpperCase()] = false;
      }
    }
  }

  if (params.has('fh')) {
    const rawHidden = params.get('fh');
    for (const letter of rawHidden) {
      if (out.future[letter.toUpperCase()]) {
        out.future[letter.toUpperCase()] = false;
      }
    }
  }

  out.floodingLikely = params.get('fl') !== 'false';
  return out;
};

const parseResources = (params) => {
  const out = {
    traffic: false,
    restAreas: false,
    infoCenters: false,
    cameras: false,
    open: false,
    linksOpen: false,
  };
  if (!params.has('r')) {
    return out;
  }
  const ourParam = params.get('r');
  for (const key of Object.keys(out)) {
    const firstLetter = key.slice(0, 1);
    if (ourParam.includes(firstLetter)) {
      out[key] = !out[key];
    }
  }
  return out;
};

const parseMap = (params, removeHash) => {
  const mapOptions = getDefaultLocation(removeHash);
  if (params.has('ll')) {
    const [lng, lat] = params.get('ll').split(',').map((num) => parseFloat(num));
    mapOptions.center = { lat, lng };
  }
  if (params.has('z')) {
    mapOptions.zoom = parseInt(params.get('z'), 10);
  }
  if (params.has('origin_place_id') && params.has('origin_label')) {
    mapOptions.origin = {
      placeId: params.get('origin_place_id'),
    };
    mapOptions.originLabel = params.get('origin_label');
    // only care about dest if we have origin
    if (params.has('destination_place_id') && params.has('destination_label')) {
      mapOptions.destination = {
        placeId: params.get('destination_place_id'),
      };
      mapOptions.destinationLabel = params.get('destination_label');
    }
  }
  return mapOptions;
};

const parsePreview = (params) => {
  const out = {
    isPreview: params.get('preview') === 'true',
  };
  if (!out.isPreview) {
    return out;
  }
  out.splashscreen = params.get('splashscreen');
  if (params.has('disabledCurrentConditions')) {
    out.disabledCurrentConditions = params.getAll('disabledCurrentConditions');
  }
  if (params.has('showing')) {
    out.showing = parseInt(params.get('showing'), 10);
  }
  return out;
};

const parseUrl = ({ removeHash } = {}) => {
  const params = (new URL(document.location)).searchParams;
  const out = {
    conditions: parseConditions(params),
    resources: parseResources(params),
    map: parseMap(params, removeHash),
    print: params.get('print') === 'true',
    preview: parsePreview(params),
  };

  return out;
};

export default parseUrl;
