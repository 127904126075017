import { useSelector, useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';

import { getTraffic, getRestAreas, getInfoCenters, getTrafficCameras, toggleResourceMenu, getResourceMenu } from '../../reducers/resources';
import SidebarToggle from './SidebarToggle';
import { getContraflowStatus } from '../../reducers/contraflow';

import resources from '../../config/more-resources';
import SidebarSection from './SidebarSection';
import { getPrintMode } from '../../reducers/print';

const MoreResources = () => {
  const dispatch = useDispatch();
  const resourceMenuOpen = useSelector(getResourceMenu);
  const isContraflowOpen = useSelector(getContraflowStatus);

  const toggleState = {
    trafic: useSelector(getTraffic),
    restAreas: useSelector(getRestAreas),
    infoCenters: useSelector(getInfoCenters),
    trafficCameras: useSelector(getTrafficCameras),
  };

  const onAccordionClick = () => dispatch(toggleResourceMenu(!resourceMenuOpen));
  const toggleValues = Object.values(toggleState);
  // track whether any toggles are enabled
  const toggleOn = toggleValues.some((toggle) => toggle);
  // get whether print query param is present in URL
  const printStatus = useSelector(getPrintMode);
  const printModeOpen = printStatus && toggleOn;
  // don't show the section if in print mode but no toggles are on
  const hideComponent = printStatus && !toggleOn;
  if (hideComponent) return null;

  return (
    <SidebarSection
      text="More Resources"
      open={printStatus ? printModeOpen : resourceMenuOpen}
      onChange={onAccordionClick}
    >
      <FormGroup>
        {resources.filter(({ contra }) => {
          if (!isContraflowOpen) {
            return true;
          }
          return contra;
        }).map(({ id, name, imgSrc, Icon, help, handleToggle, contraHelp }) => (
          <SidebarToggle
            key={id}
            name={name}
            checked={toggleState[id]}
            Icon={Icon}
            imgSrc={imgSrc}
            onChange={() => dispatch(handleToggle(!toggleState[id]))}
            tooltip={isContraflowOpen ? contraHelp || help : help}
          />
        ))}
      </FormGroup>
    </SidebarSection>
  );
};

export default MoreResources;
