import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import parseUrl from '../utils/parse-url';
import { setConditionsMenuOpen } from './conditions';
import { setTrafficUtil, setLayer } from '../utils/map-google/mapUtils';
import { getIsMobile } from './app';
// TODO: add default initial state in comment here

const resourceRouter = createSlice({
  name: 'resources',
  initialState: parseUrl().resources,
  reducers: {
    toggleTraffic(state, { payload }) {
      setTrafficUtil(payload);
      state.traffic = payload;
    },
    toggleRestAreas(state, { payload }) {
      setLayer('rest-areas', payload);
      state.restAreas = payload;
    },
    toggleInfoCenters(state, { payload }) {
      setLayer('information-centers', payload);
      state.infoCenters = payload;
    },
    toggleTrafficCameras(state, { payload }) {
      setLayer('traffic-cameras', payload);
      state.cameras = payload;
    },
    setResourceMenuOpen(state, { payload }) {
      state.open = payload;
    },
    setLinksMenuOpen(state, { payload }) {
      state.linksOpen = payload;
    },
  },
});

export default resourceRouter.reducer;

export const { toggleTraffic } = resourceRouter.actions;
export const getTraffic = (state) => state.resources.traffic;
export const { toggleRestAreas } = resourceRouter.actions;
export const getRestAreas = (state) => state.resources.restAreas;
export const { toggleInfoCenters } = resourceRouter.actions;
export const getInfoCenters = (state) => state.resources.infoCenters;
export const { toggleTrafficCameras } = resourceRouter.actions;
export const getTrafficCameras = (state) => state.resources.cameras;
export const { setResourceMenuOpen } = resourceRouter.actions;
export const getResourceMenu = (state) => state.resources.open;
export const { setLinksMenuOpen } = resourceRouter.actions;
export const getLinksMenu = (state) => state.resources.linksOpen;

export const toggleLinksMenu = createAsyncThunk('resources/toggleLinkMenu', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setLinksMenuOpen(payload));
  // if opening menu, close other sections
  const isMobile = getIsMobile(thunkAPI.getState());
  if (payload && isMobile) {
    thunkAPI.dispatch(setResourceMenuOpen(false));
    thunkAPI.dispatch(setConditionsMenuOpen(false));
  }
});

export const toggleResourceMenu = createAsyncThunk('resources/toggleResourceMenu', async (payload, thunkAPI) => {
  thunkAPI.dispatch(setResourceMenuOpen(payload));
  // if opening menu, close other sections
  const isMobile = getIsMobile(thunkAPI.getState());
  if (payload && isMobile) {
    thunkAPI.dispatch(setLinksMenuOpen(false));
    thunkAPI.dispatch(setConditionsMenuOpen(false));
  }
});
