import { createSlice } from '@reduxjs/toolkit';
import parseUrl from '../utils/parse-url';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isMobile: false,
    preview: parseUrl().preview,
    sidebarOpen: true,
  },
  reducers: {
    setIsMobile: (state, { payload }) => {
      state.isMobile = payload;
    },
    // not sure we'll need to change it, but hey it's here if we need it
    setIsPreview: (state, { payload }) => {
      state.preview.isPreview = payload;
    },
    setSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
  },
});

export const { setIsMobile, setIsPreview, setSidebarOpen } = appSlice.actions;

export const getIsMobile = (state) => state.app.isMobile;
export const getIsPreview = (state) => state.app.preview.isPreview;
export const getPreviewSplashscreen = (state) => state.app.preview.splashscreen;
export const getPreviewDisabled = (state) => state.app.preview.disabledCurrentConditions;
export const getPreviewShowing = (state) => state.app.preview.showing;
export const getSidebarOpen = (state) => state.app.sidebarOpen;

export default appSlice.reducer;
